.main-container {
  // padding: 20px;
}
.title {
  color: #1e1a1a;
  font-size: 18px;
  font-weight: 500;
  padding: 25px 20px;
  border: 1px solid #eceef0;
}
.status {
  height: 13px;
  width: 13px;
  background-color: #ffda97;
  border-radius: 50%;
  border: 2px solid #bbb6b6;
}
.status-container {
  padding: 0 20px;
  border: 1px solid #eceef0;
  color: #464646;
}
.status-title {
  display: flex;
  column-gap: 20px;
  align-items: center;
  height: 70px;
}
.status-time-container {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  column-gap: 20px;
}
.status-time {
  display: flex;
  border: 1px solid #d4dadf;
  height: 70%;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}
.selected-time {
  background-color: #e4f5f1;
  color: #0cb88f;
}

.tick-icon {
  height: 20px;
  width: 20px;
  background-image: url("../images/tickMark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.warning-container {
  display: flex;
  padding: 10px 20px;
  font-size: 12px;
  background-color: #fff6e5;
  border-radius: 5px;
  margin-bottom: 10px;
  align-items: center;
  column-gap: 8px;
}
.warning-icon {
  height: 20px;
  width: 20px;
  background-image: url("../images/InfoIcon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.bold {
  font-weight: 600;
}
.footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  padding: 20px;
  background-color: #eceef0;
}
