.base {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;

  .content {
    width: 100%;
    max-width: 85vw;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .info-section {
      width: 73%;
      .info-header {
        width: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 2px solid rgb(197, 193, 193);
        font-size: 15px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
      }
      .info-info {
      }
    }
  }
}

.card-base {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: calc(100vh - 100px);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4dadf;
  padding: 15px;
  position: relative;
  .card-head {
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    display: flex;
    .back-arrow {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background: url("./images/back.svg") no-repeat;
      background-position: center;
    }
    .arrow-txt {
      font-size: 12px;
    }
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    .card-img {
      object-fit: contain;
      max-width: 50%;
      height: 50px;
    }
    .status-tag {
      width: auto;
      height: 24px;
      background: #12b76a 0% 0% no-repeat padding-box;
      border-radius: 20px;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
    }
  }
  .card-info {
    margin-top: 15px;
    .card-header-info {
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0px;
      color: #363636;
      opacity: 0.8;
    }
    .desc {
      // height: 48px;
      text-align: left;
      font: normal normal normal 12px/16px Roboto;
      letter-spacing: 0px;
      color: #363636;
      opacity: 0.8;
      margin-top: 3%;
    }
    .sub-desc {
      font: normal normal normal 10px/13px Roboto;
      letter-spacing: 0px;
      color: #363636;
      opacity: 0.6;
    }
  }
  .card-oprtn {
    padding-top: 30px;
    gap: 15px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    .card-btn {
      text-align: left;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0px;
      font-weight: 500;
      color: #48546a;
      text-transform: uppercase;
      // padding-right: 20px;
      text-decoration: none;
      padding: 3px 5px;
      background-color: rgba(128, 128, 128, 0.1);
      cursor: pointer;
    }
    .card-btn:hover {
      background-color: rgba(128, 128, 128, 0.4);
      padding: 3px 5px;
      border-radius: 5px;
    }
    .card-site-nav-icon {
      width: 24px;
      height: 24px;
      background: transparent;
      background-image: url("./images/visit-icon.svg");
      background-size: contain;
      background-size: 24px;
      background-position: center;
      cursor: pointer;
    }
    .card-more-option {
      width: 24px;
      height: 24px;
      background: transparent;
      background-image: url("./images/list-option.svg");
      background-size: contain;
      background-size: 24px;
      background-position: center;
      cursor: pointer;
    }
  }
}

.calling-error {
  width: 100%;
  height: 80vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #36363698;
}
.int-base {
  background-color: white;
  border: 1px solid #d4dadf;
  height: calc(100vh - 100px);
  .flex-col {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 215px);
    .config {
      padding: 15px 15px 15px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      .accordian-div {
        display: flex;
        flex-direction: column;
        background: #f7f7f7 0% 0% no-repeat padding-box;
        border: 1px solid #e3e3e38f;
        padding: 10px;
        margin: 5px;
        border-radius: 4px;
        .accordian-text {
          color: rgb(54, 54, 54);
          font-size: 14px;
          text-align: left;
          display: flex;
          align-items: center;
          position: relative;
        }
        .arrow-up {
          position: absolute;
          right: 15px;
          width: 15px;
          height: 15px;
          // background: url("./images/accordian-arrow.svg") no-repeat;
          background-size: contain;
          background-position: center;
          transform: rotate(90deg);
          transition: 300ms;
          cursor: pointer;
        }
        .delete-icon {
          width: 20px;
          height: 20px;
          background: url("./images/delete-green.svg") no-repeat;
          background-size: cover;
          background-position: center;
          cursor: pointer;
        }
        .add-icon {
          width: 20px;
          height: 20px;
          background: url("./images/add-more.svg") no-repeat;
          background-size: cover;
          background-position: center;
          cursor: pointer;
        }
        .arrow-down {
          @extend .arrow-up;
          transform: rotate(270deg);
          transition: 300ms;
        }
        .inpt-holder {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          .add-btn {
            border: 1px solid #0cb88f;
            border-radius: 6px;
            padding: 6px 12px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.22px;
            color: #0cb88f;
            cursor: pointer;
            margin-right: 10px;
          }
        }
        .col {
          position: relative;
          margin-top: 10px;
          border-radius: 4px;
          width: 50%;
          padding-right: 10px;
          height: auto;
          margin-bottom: 10px;
        }
        .col-30 {
          position: relative;
          margin-top: 10px;
          border-radius: 4px;
          width: 48%;
          padding-right: 10px;
          height: auto;
          margin-bottom: 10px;
        }
        .other {
          .flex-wrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 10px;
            .ele {
              border: 1px solid #e3e3e3d9;
              font-size: 14px;
              padding-left: 10px;
              border-radius: 4px;
              background-color: white;
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 10px;
      padding-right: 15px;
      .cancel {
        margin-right: 10px;
      }
    }
  }
}
.edit-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  position: absolute;
  bottom: 10px;
  right: 15px;
}
.toggle-label {
  font-size: 12px;
  font-weight: normal;
  color: black;
}
.table-content {
  background-color: #ffffff;
  padding: 10px;
  overflow: auto;
  position: relative;
}
.add-agent-button {
  display: flex;
  padding: 15px;
  column-gap: 20px;
}

.table-title {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: sans-serif;
}
.table-container {
  width: 100%;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 7px;
}
.table-container th {
  background-color: #f2f2f2;
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
}

/* Styling for the table rows */
.table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
.table-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.arrow-up {
  background: url("./images/accordian-arrow.svg") no-repeat;
  background-size: contain;
  background-position: center;
  transform: rotate(90deg);
  transition: 300ms;
  padding: 10px;
  cursor: pointer;
}
.down-arrow {
  @extend .arrow-up;
  transform: rotate(-90deg);
}
.actions {
  display: flex;
  justify-content: space-around;
}
.edit-icon {
  width: 20px;
  height: 20px;
  background: url("./images/editIcon.svg") no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.delete-icon {
  width: 20px;
  height: 20px;
  background: url("./images/delete-green.svg") no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.save-icon {
  width: 20px;
  height: 20px;
  background: url("./images/save.svg") no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.cancel-icon {
  width: 20px;
  height: 20px;
  background: url("./images/cancel.svg") no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.add-icon {
  width: 20px;
  height: 20px;
  background: url("./images/add-more.svg") no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.input-border {
  border: 1px solid #918989;
  border-radius: 5px;
  padding: 2px;
}
.footer-button {
  border-top: 1px solid rgb(172, 169, 169);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
.no-data {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}
