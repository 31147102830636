.base {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
}
.content {
  width: 100%;
  height: 100%;
  padding: 0px 14px;
  display: flex;
  align-content: center;
  position: relative;
  .search-icon {
    position: absolute;
    top: 50%;
    right: 14px;
    background: url("../images/search.svg") no-repeat;
    background-position: center;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);
    cursor: pointer;
    background-size: contain;
  }
}
.input {
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  padding-right: 20px;
}
.drop-down {
  width: 100%;
  height: auto;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  top: 36px;
  font-size: 12px;
  left: 0;
  z-index: 1;
  border: 1px solid #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #fff;
}
.drop-down:empty {
  display: none;
}
.drop-down-top {
  composes: drop-down;
  top: 0;
  transform: translateY(-100%);
}
.option {
  width: 100%;
  min-height: 40px;
  line-height: 16px;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
