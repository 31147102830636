.base {
    width: 100%;
  }
.content{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 80px;
}
.error-code{
    font-size: 150px;
     font-weight: 500;;
    color: #000;
}
.error-description{
    font-size: 25px;
    font-weight: 500;;
    color: rgb(230, 10, 10);
}