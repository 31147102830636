.base {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://www.nobrokerhood.com/nbhood-new/public//hood_loader.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.loader{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}