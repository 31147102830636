@import "../../variables.scss";

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.base {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 110;
}
.panel {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}
.window {
  width: 800px;
  max-width: 100%;
  height: auto;
  background: $white;
  position: absolute;
  border-radius: 3px;
  max-height: 90vh;
  overflow: auto;
}
.full-window {
  width: 80%;
  max-width: 100%;
  height: 90vh;
  background: #fff;
  position: absolute;
  border-radius: 3px;
  max-height: 90vh;
  overflow: auto;
}
.animation-window {
  width: 80%;
  max-width: 100%;
  height: 90vh;
  background: #fff;
  position: absolute;
  border-radius: 3px;
  max-height: 90vh;
  overflow: auto;
  animation: slideInFromLeft 1s 1;
}
.header {
  width: 100%;
  height: 60px;
  position: relative;
  text-align: center;
  background: $black3;
  color: $white;
  text-align: center;
}
.cross {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  background: $white;
  top: 0;
  cursor: pointer;
  bottom: 0;
  margin: auto;
}
