@import "../../variables.scss";
.base {
  position: fixed;
  right: 20px;
  top: 100px;
  z-index: 999;
}
.toast {
  min-width: 275px;
  max-width: 400px;
  height: 90px;
  border-radius: 5px;
  background: $white;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 1px 1px #d1d1d1;
  transform: translateX(110%);
  animation: slideInOut 3600ms ease-in forwards;
  margin-top: 5px;
}
.toast:first-child {
  margin-top: 0;
}
.visible {
  composes: base;
  animation: slideOut 300ms ease-in forwards;
}
@keyframes slideInOut {
  0% {
    transform: translateX(110%);
  }
  8% {
    transform: translateX(0%);
  }
  92% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(110%);
  }
}
@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(110%);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0%);
  }
}
.content {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.icon {
  content: "";
  width: 90px;
  height: 90px;
  display: block;
  padding: 20px 25px;
  border-radius: 5px;
  box-sizing: border-box;
  flex-shrink: 0;
  background: $grey3
    url(https://images.nobroker.in/static/img/alertIcons/warning.png) no-repeat
    center;
}
.info {
  composes: icon;
}
.error {
  composes: icon;
  background: $red
    url(https://images.nobroker.in/static/img/alertIcons/error.png) no-repeat
    center;
}

.warning {
  composes: icon;
  background: $yellow
    url(https://images.nobroker.in/static/img/alertIcons/warning.png) no-repeat
    center;
}

.success {
  composes: icon;
  background: $green2
    url(https://images.nobroker.in/static/img/alertIcons/success.png) no-repeat
    center;
}
