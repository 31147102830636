@import "../variables.scss";

.file-base {
  width: 100%;
  height: auto;
  padding-bottom: 100%;
  position: relative;
  padding: 0px;
}
.file-base input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.file-content {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.file-imageLabel {
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}
.input-holder {
  border: 1px solid #333333;
  border-radius: 4px;
  padding: 6px;
  width: 100%;
  color: $black;
}
.file-choose {
  background: #333333;
  color: $black;
  border-radius: 3px;
  width: 40%;
  text-align: center;
  padding: 2px;
  font-size: 12px;
}
.file-container {
  padding: 5px;
  background: $white;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.flex {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-with-ml50 {
  composes: flex;
  margin-left: 50px;
}
.download {
  font-size: 12px;
  font-weight: 500;;
  letter-spacing: 0px;
  color: #fd3753;
  position: relative;
  padding-left: 20px;
  padding-right: 5px;
}
.download::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url("../images/download-red.svg");
  background-position: center;
  width: 14px;
  height: 14px;
}
.fileChoose {
  background: $green;
  color: $white;
  height: 36px;
  line-height: 36px;
  padding: 0 25px;
  border-radius: 3px;
  font-size: 16px;
  width: auto;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
}
