@import "../variables.scss";
.base {
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 0px 20px;
  display: flex;
  align-items: center;
}
.logo-container {
  padding-right: 40px;
  border-right: 1px solid #e1e1e1;
  line-height: 21px;
  .logo-company-name {
    font-size: 11px;
    color: #1c1c1cbf;
  }
  .logo {
    font-size: 16px;
    font-weight: 700;
    color: #7956bc;
  }
}
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #8e8e8e;
  margin-left: 20px;
  .nav-list {
    padding: 0px 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .nav-list-active {
    @extend .nav-list;
    // font-weight: 500;
    color: #7956bc;
    &::before {
      content: "";
      width: 100%;
      height: 3px;
      background-color: #7956bc;
      position: absolute;
      bottom: -20px;
      left: 0px;
    }
  }
}
.icon {
  width: 15px;
  height: 15px;
  background-image: url("./images/dashboard.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.dashboard {
  @extend .icon;
}
.dashboard-active {
  @extend .dashboard;
  background-image: url("./images/dashboard-active.svg");
}
.ticket {
  @extend .icon;
  background-image: url("./images/ticket.svg");
}
.ticket-active {
  @extend .icon;
  background-image: url("./images/ticket-active.svg");
}
.crm {
  @extend .icon;
  background-image: url("./images/crm.svg");
}
.crm-active {
  @extend .icon;
  background-image: url("./images/crm-active.svg");
}
.notification-icon {
  width: 36px;
  height: 36px;
  //   border: 1px solid #7956bc;
  border-radius: 8px;
  padding: 18px;
  position: relative;
  background-image: url("../images/flatBellBlack.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  //   background-color: #7956bc;
  cursor: pointer;
  .notification-count {
    height: 16px;
    min-width: 16px;
    border-radius: 10px;
    background: $red;
    color: #fff;
    padding: 0 5px;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    right: -2px;
    top: -2px;
  }
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.attendance-container {
  //   border: 1px solid #7956bc;
  //   background-color: #7956bc;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  display: flex;
  column-gap: 12px;
  align-items: center;
}
.punch-time {
  font-size: 11px;
  display: flex;
  flex-direction: column;
  color: #7956bc;
  align-items: flex-end;
}
.flex-gap-10 {
  gap: 10px;
  display: flex;
  align-items: center;
  //   background: #7956bc;
  border-radius: 10px;
}
