@import "./variables.scss";

.title-container {
  display: flex;
  padding: 15px 10px 0px;
  margin: 0 16px;
  align-items: center;
  border-bottom: 3px solid #d4d4d4e5;
  min-height: 57px;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.meeting-title {
  font-weight: 500;
  margin: 10px 0 20px;
  color: #5d5d5d;
}
.meeting-container {
  padding: 10px 25px 30px;
}
.meeting-card-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.no-meeting {
  font-weight: 400;
  text-align: center;
  border: 1px solid #dfd4d4;
  border-radius: 5px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
