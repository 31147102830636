@import "../../variables.scss";

.base {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
}
.header {
  width: 100%;
  height: 60px;
  border-top: 1px solid $card-border;
  border-bottom: 1px solid $card-border;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
}
.main-container {
  width: 100%;
  height: calc(100vh - 120px);
  background: #e8ecf1 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  width: 100%;
  max-width: 85vw;
  padding: 20px 10px;
  display: flex;
}
.left-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1 1 auto;
  margin-left: 20px;
  margin-right: 40px;
  header {
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
  }
  .task-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: start;
    gap: 12px;
    align-items: stretch;
    overflow-y: scroll;
    flex-grow: 1;
    margin-left: 0px;
    width: 680px;
  }
}
.right-section {
  min-width: 300px;
  height: 520px;
  margin-right: 40px;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
}
.card-base {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.card {
  border-radius: 5px;
  height: 200px;
  width: 328px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  padding: 10px;
}
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}
.skeleton-white-box {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#dddbdd, 0) 0,
      rgba(#dddbdd, 0.2) 20%,
      rgba(#dddbdd, 0.5) 60%,
      rgba(#dddbdd, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
