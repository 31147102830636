
.main-container{
    position: relative;
    height: 35vh;
    padding: 30px;
    font-family: sans-serif;
}
.text-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.title{
    color: #363636;
    font-size: 24px;
    font-weight: 600;
}
.sub-title{
    color: #5E5E5E;
    font-size: 16px;
    font-weight: 400;

}
.footer{
    display: flex;
    position: absolute;
    bottom: 15px;
    right: 20px;
    justify-content:flex-end ;
    column-gap: 10px;
}
