@import "../../variables.scss";

.base {
  width: 100%;
  height: auto;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.base-custom {
  @extend .base;
  max-height: 200px;
  overflow: auto;
}

.button {
  width: auto;
  position: relative;
  padding-top: 25px;
  display: inline-block;
  min-width: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.row {
  width: 100%;
  min-height: 40px;
  line-height: 16px;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 15px;
  display: flex;
  align-items: center;
  position: relative;
}

.row-highlight {
  composes: row;
  background: $grey7;
}

.row:hover {
  background: $grey7;
  color: $black;
  transition: 300ms;
}

.button:before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 3px;
  border: 1px solid $light-grey;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 1;
  transition: 300ms;
}

.button:after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 3px;
  border: 1px solid $green;
  background: $green;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: 300ms;
}

.buttonActive {
  composes: button;
}

.buttonActive.button:after {
  opacity: 1;
}

.buttonActive.button:before {
  opacity: 0;
}

.label {
  font-size: 12px;
  font-weight: normal;
  ;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkMark {
  background-image: url("../../images/check-mark.svg");
  height: 15px;
  width: 15px;
  background-size: contain;
  transform: translate(-10px, 0%);
}

.checkmark-active {
  background-image: url("../../images/check-mark.svg");
  height: 15px;
  width: 15px;
  background-size: contain;
  transform: translate(-10px, 0%);
  border: 1px solid #ddd;
}

.checkmark-inactive {
  background: none;
  height: 15px;
  width: 15px;
  background-size: contain;
  transform: translate(-10px, 0%);
  border: 1px solid #ddd;
}

.city {
  font-weight: normal;
  text-transform: capitalize;
  margin-left: 2px;
  letter-spacing: 0.3px;
  font-size: 10px;
}

.email {
  font-weight: normal;
  ;
  font-size: 10px;
  color: #999;
}

.tag {
  padding: 2px 5px;
  background: $green;
  color: $white;
  border-radius: 15px;
  margin-right: 2px;
  font-size: 8px;
  font-weight: 500;
  text-transform: capitalize;
}

.footer-button {
  display: flex;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  color: #1976d2;
  background: #fff;
  border-top: 1px solid #999;
}