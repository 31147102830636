@import "../../variables.scss";

.base {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  z-index: 999;
}
.panel {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.window {
  width: 500px;
  max-width: 100%;
  height: 100%;
  background: $white;
  position: absolute;
  right: 0;
  top: 0;
  animation: slideInLeft 200ms forwards;
  max-height: 100vh;
  overflow: auto;
}

.header {
  width: 100%;
  height: 60px;
  position: relative;
  text-align: center;
  background: $black3;
  color: $white;
  text-align: center;
}
.cross {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  background: $white;
  top: 0;
  cursor: pointer;
  bottom: 0;
  margin: auto;
}
@keyframes slideInLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
