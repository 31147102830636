@import "../variables.scss";

.base {
  width: 100%;
  height: 100%;
  position: relative;
}

.button {
}

.button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../images/vertical-dot.svg") no-repeat;
  background-position: center;
  background-size: 24px;
  transition: 300ms;
}

.button-active {
  composes: button;
}

.button-active.button:after {
  transform: rotate(45deg);
}

.pop-up {
  position: absolute;
  top: 100%;
  right: 15px;
  z-index: 10;
}

.popup {
  position: absolute;
  top: 100%;
  right: 15px;
  z-index: 10;
}

.pop-down {
  position: absolute;
  right: 15px;
  z-index: 10;
  top: initial;
  bottom: 100%;
}

.item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: Center;
  font-size: 14px;
  font-weight: normal;
  color: $black;
}

.itemIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}

.account {
  composes: itemIcon;
  background-image: url("../images/account.svg");
}

.contact {
  composes: itemIcon;
  background-image: url("../images/contact.svg");
}

.appointment {
  composes: itemIcon;
  background-image: url("../images/meeting.svg");
}

.task {
  composes: itemIcon;
  background-image: url("../images/task.svg");
}

.lead {
  composes: itemIcon;
  background-image: url("../images/lead.svg");
}

@import "../variables.scss";

.dropdown {
  position: relative;
}

.dropdown.open > .dropdown-menu {
  display: block;
}

.dropdown .dropdown-toggle {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  color: $black;
  background-color: $white;
  border: 1px solid $card-border;
  border-radius: 4px;
  width: 100%;
}

.dropdown .dropdown-toggle .caret {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  background-color: $white;
}

.dropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 5;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  background-color: $white;
  border: 1px solid $card-border;
  border-radius: 4px;
  box-shadow: rgba($color: $card-shadow, $alpha: 0.175);
}

.dropdown .dropdown-menu li {
  cursor: pointer;
}

.dropdown .dropdown-menu li :hover {
  background: #ddd;
}

.dropdown .dropdown-menu li > a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.dropDown-top {
  composes: dropdown;
  top: 0;
  transform: translateY(-100%);
}

.options {
  width: 100%;
  min-height: 40px;
  line-height: 16px;
  cursor: pointer;
  background: $white;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.options:hover {
  background: $grey7;
  color: $black;
  transition: 300ms;
}

.more {
  width: 28px;
  height: 28px;
  background-image: url("../images/horizantal-dot.svg");
  background-position: center;
  background-size: 20px;
  border-radius: 3px;
  background-repeat: no-repeat;
}

.custom-options {
  width: 100%;
  min-height: 40px;
  line-height: 16px;
  cursor: pointer;
  background: $white;
  border-bottom: 1px solid $light-grey;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.custom-options:hover {
  background: $grey7;
  color: $black;
  transition: 300ms;
}

.checkMark {
  background-image: url("../images/check-mark.svg");
  height: 15px;
  width: 15px;
  background-size: contain;
  transform: translate(-10px, 0%);
}
.left {
  left: 0px;
}
.dropdown-overflow {
  max-height: 150px;
  overflow: auto;
  position: relative;
}
