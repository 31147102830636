@import "../variables.scss";

.single-base {
  width: 100%;
  height: auto;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.single-row {
  display: flex;
  padding: 10px 10px 10px 0px;
  flex-shrink: 0;
  width: 100%;
}

.button {
  width: auto;
  position: relative;
  padding-top: 15px;
  display: inline-block;
  min-width: 16px;
  cursor: pointer;
}

.button:before {
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid $black;
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: 300ms;
}

.button:after {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $green;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 300ms;
}

.button-active {
  width: auto;
  position: relative;
  padding-top: 15px;
  display: inline-block;
  min-width: 16px;
  cursor: pointer;
}

.button-active:after {
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid $green;
  background: url("../images/checkmarkWhite.svg") no-repeat;
  background-color: $green;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  opacity: 1;
}

.button-active:before {
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid $black;
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: 300ms;
  opacity: 0;
}

.label {
  font-size: 14px;
  color: $black;
  padding-left: 10px;
  font-weight: 500;
}

.error {
  font-weight: 400;
  bottom: -16px;
  font-size: 11px;
  color: $red;
  padding: 5px;
  margin-bottom: 15px;
  text-align: right;
}