.base {
    width: 100%;
    height: auto;
    padding: 16px;
    background-color: #f2f3f7;
    border-bottom: 1px solid #d3dae07d;
    .flex {
        display: flex;
        align-items: center;
    }

    .flex-btw {
        @extend .flex;
        justify-content: space-between;
    }

    .icon {
        width: 24px;
        height: 24px;
        background-image: url("./images/bell.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        // border-radius: 50%;
        margin-right: 12px;
    }

    .task {
        @extend .icon;
        background-image: url("./images/Tasks_notification.svg");
    }

    .meeting {
        @extend .icon;
        background-image: url("./images/Meetings_notification.svg");
    }

    .ticket {
        @extend .icon;
        background-image: url("./images/Tickets_notification.svg");
    }

    .job {
        @extend .icon;
        background-image: url("./images/Jobs_notification.svg");
    }

    .lead {
        @extend .icon;
        background-image: url("./images/Leads_notification.svg");
    }

    .approval {
        @extend .icon;
        background-image: url("./images/approvals_notification.svg");
    }

    .note {
        @extend .icon;
        background-image: url("./images/add-notes.svg")
    }

    .quotation {
        @extend .icon;
        background-image: url("./images/Quotations_notification.svg");
    }

    .contact {
        @extend .icon;
        background-image: url("./images/contact.svg");
    }

    .document {
        @extend .icon;
        background-image: url("./images/documentUpload.svg");
    }

    .bulk-import {
        @extend .icon;
        background-image: url("./images/leadImport.svg");
    }

    .title {
        font-size: 12px;
        font-weight: 500;
        color: #363636;
        width: calc(100% - 24px);
    }

    .description {
        font-size: 12px;
        font-weight: 400;
        color: #363636;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .date-info {
        font-size: 10px;
        font-weight: 400;
        color: rgba(54, 54, 54, 0.8);
    }

    .warning {
        font-size: 10px;
        font-weight: 400;
        color: rgba(255, 46, 46, 0.8);
    }

    .button-holder {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .button-active {
            width: 100%;
            border-right: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #51b595; //
            font-size: 12px;
            font-weight: 500;
            padding: 10px; //
            border: 1px solid #51b595; //
            cursor: pointer;
        }

        .button {
            width: 100%;
            border-right: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            // color: #51b595; //
            font-size: 12px;
            font-weight: 500;
            // padding: 10px; //
            // border: 1px solid #51b595; //
            cursor: pointer;
        }

        .button:last-child {
            border-right: none;
        }
    }
}