$green: #00b893;
$dark-green: #0c9675;
$green2: #0cb88f;
$green-background: rgba(12, 184, 143, 0.1);
$white: #fff;
$black: #000000de;
$black2: #363636cc;
$black3: #00000099;
$grey: #00000099;
$grey2: #bebebe;
$light-grey-background: #efefef;
$grey3: #464646;
$grey4: #dedede;
$grey5: #0000001a;
$grey6: #363636cc;
$grey7: #ebebeb;
$grwy8: #ebeaea;
$grey9: #dddddd;
$light-grey2: #B6A6A6;
$light-grey3: #e5d1d1;
$box-shadow-color: #00000033;
$box-shadow-color2: #00000014;
$dark-blue: #1976d2;
$yellow: #ffa000;
$light-grey: #f5f5f5;
$red: #ff435e;
$grey-text: #666666;
$card-border: #d3dae0;
$card-border2: #ebeef0;
$card-shadow: #0000000f;
$header-background: #f4f6f8;
$pink: #ffc6ce;
$light-pink: #fae2e5;
$light-pink2: #FBE9ED;
$dark-pink: #fd3753;
$light-border: #f2f2f2;
$border-gray: #e4e4e4;
$grey8: #949494;
$black4: #000000cc;
$light-grey-border: #e0e0e0;
$hover-color: #ececec;
$gray-lighter: #999999 !default;
$grayBg: #f5f5f5;
$primary-color: #fd3752 !default;
$grey-border: #949494;
$light-orange: #FFDA97;
