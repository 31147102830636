.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 12px;
  margin: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cbcbcb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -4px;
  background-color: #949494;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(94, 178, 142, 0.4);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgba(94, 178, 142, 0.4);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #0c6edf;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.container {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 2px solid #9191917d;
  border-radius: 4px;
}
.checkmark-disabled {
  @extend .checkmark;
  background-color: #ecedeb;
  cursor: not-allowed;
}
.container:hover input ~ .checkmark {
  background-color: #fff;
}
.container input:checked ~ .checkmark {
  background-color: #0c6edf;
  border: 1px solid #0c6edf;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/checkmarkWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}
