@import "./variables.scss";

.dropdown {
    position: relative;
}

.dropdown.open>.dropdown-menu {
    display: block;
}

.dropdown .dropdown-toggle {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    color: $black;
    background-color: $white;
    border: 1px solid $card-border;
    border-radius: 4px;
    width: 100%;
}

.dropdown .dropdown-toggle .caret {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    vertical-align: top;
}

.dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0px;
    z-index: 1000;
    min-width: 260px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    background-color: $white;
    border: 1px solid $card-border;
    border-radius: 4px;
    box-shadow: rgba($color: $card-shadow, $alpha: 0.175);
    padding: 10px;
}

.dropdown .dropdown-menu li {
    cursor: pointer;
}

.dropdown .dropdown-menu li :hover {
    background: #ddd;
}

.dropdown .dropdown-menu li>a {
    display: block;
    padding: 5px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
}

.dropDown-top {
    composes: dropdown;
    top: 0;
    transform: translateY(-100%);
}

.options {
    width: 100%;
    min-height: 40px;
    line-height: 16px;
    cursor: pointer;
    background: $white;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

.options:hover {
    background: $grey7;
    color: $black;
    transition: 300ms;
}

.filter-icon {
    width: 20px;
    height: 20px;
    background: url("./images/filter.svg") no-repeat;
    background-position: center;
    background-size: contain;
}

.tag-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0px;
    border-bottom: 1px solid #ececec;
}

.tag-container::-webkit-scrollbar {
    display: none;
}

.tag {
    padding: 5px 10px;
    background: #ececec;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
}

.tag-active {
    @extend .tag;
    background: #FD3753;
    color: #fff;
}

.header {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.flex {
    display: flex;
    align-items: center;
}

.mgr-8 {
    margin: 0px 8px;
}

.flex-btw {
    composes: flex;
    width: 100%;
    justify-content: space-between;
}

.text-active {
    color: #000000de;
    font-weight: 500;
    font-size: 16px;
}

.text-inactive {
    color: #dbdbdb;
    font-weight: 500;
    font-size: 16px;
}