@import "../variables.scss";

.button {
  width: auto;
  display: inline-block;
  height: 36px;
  background: $green;
  color: #fff;
  line-height: 36px;
  text-align: center;
  padding: 0 25px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 4px;
}

.button-loading {
  composes: button;
  pointer-events: none;
}
.primary {
  width: auto;
  display: inline-block;
  height: 36px;
  background: $white;
  color: $black;
  line-height: 36px;
  text-align: center;
  padding: 0 25px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.primary-loading {
  compose: primary;
  pointer-events: none;
  font-size: 0;
}
.secondary {
  width: auto;
  display: inline-block;
  height: 36px;
  color: $black;
  line-height: 36px;
  text-align: center;
  padding: 0 25px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  background-color: $white;
  position: relative;
  border: 1px solid $border-gray;
  border-radius: 4px;
}
.secondary-loading {
  compose: secondary;
  pointer-events: none;
  font-size: 0;
}
.tertiary {
  composes: button;
  color: $white;
  background: $black;
}
.tertiary-loading {
  compose: tertiary;
  pointer-events: none;
  font-size: 0;
}
.quaternary {
  composes: button;
  color: #333333;
  background: #eaeaea;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  justify-content: center;
}
.quaternary-loading {
  compose: quaternary;
  pointer-events: none;
  font-size: 0;
}
.button-loading:after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid #f3f3f3; 
  border-top: 4px solid #000; 
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}