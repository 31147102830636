@import "./variables.scss";

.base {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  padding-top: 60px;
}

.main {
  display: flex;
  margin-top: 50px;
}

.row {
  width: 100%;
  margin-top: 20px;
}

.row:first-child {
  margin: 0;
}

.content {
  width: 100%;
  max-width: 85vw;
  padding: 20px 10px; //70
}

.content-with-extraPadding {
  composes: content;
  padding: 76px 10px; //126
}

.text-button {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: #0cb88f;
}

.header {
  width: 100%;
  height: 60px;
  border-top: 1px solid $card-border;
  border-bottom: 1px solid $card-border;
  background: $header-background;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  top: 60px;
}

.header-content {
  @extend .content;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header-section {
  width: auto;
  height: 100%;
  display: inline-flex;
  align-items: center;

  .text {
    margin-left: 20px;
  }

  .lead-dropdown {
    font-size: 10px;
  }
}

.buttonLeft {
  padding-left: 10px;
}

.filter-dropdown {
  width: 170px;
  padding: 0px 10px;
}

.lead-dropdown {
  width: 230px;
  padding: 0px 10px;
  position: relative;
}

.text {
  font-size: 13px;
  color: $grey;
  padding-left: 5px;
}

.searchbar-holder {
  width: 280px;
  height: 34px;
  margin-left: 20px;
}

.cross {
  content: "";
  background: url("./images/cancel.svg") no-repeat;
  background-position: top;
  background-size: cover;
  position: absolute;
  width: 15px;
  height: 15px;
  opacity: 0.8;
  top: 8px;
  right: 40px;
  z-index: 999;
  cursor: pointer;
}

.cross:hover {
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
}

.edit-button {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-info {
  white-space: nowrap;
  margin-left: 10px;
  font-weight: 500;
  color: #000000de;
  font-size: 12px;
}

.assignee-content-container {
  width: 100%;
  height: auto;
  padding: 10px 0px;
  position: sticky;
  top: 0;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: fixed;
  top: 120px;
  box-shadow: 0px 3px 6px #00000014;
}

.assignee-content {
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  max-width: 85vw;
  padding: 0px 10px;
}

.sales-assignee-conatiner {
  width: 60%;
  height: auto;
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.label {
  width: 100%;
  font-size: 11px;
  color: $black3;
}

.info {
  width: 20%;
  margin-right: 10px;
}

.toggle-label {
  font-size: 12px;
  margin-left: 5px;
  font-weight: normal;
  color: $black;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.up-icon {
  width: 20px;
  height: 20px;
  background: url("./images/up.svg") no-repeat;
  background-position: center;
  background-size: 16px;
  transition: transform 0.5s;
}

.down-icon {
  composes: up-icon;
  transform: rotate(180deg);
}

.toggle-holder {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .toggle-label {
    font-size: 12px;
    margin-right: 5px;
    font-weight: normal;
    color: $black;
  }
}

.marginLeft {
  margin-left: 20px;
}

.addTask-button {
  width: auto;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.task-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.main-container {
  width: 100%;
  height: calc(100vh - 120px);
  background: #e8ecf1 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1 1 auto;
  margin-left: 20px;
  margin-right: 40px;

  header {
    background: #ffffff 0% 0% no-repeat padding-box;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;

    .text {
      padding-left: 24px;
      padding-right: 24px;
      align-self: stretch;
      padding-top: 15px;
      padding-bottom: 15px;
      cursor: pointer;
    }

    .active-tab {
      border-bottom: 4px solid #ff435e;
      color: #363636;
      font-weight: 500;
    }
  }

  .task-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: start;
    gap: 12px;
    align-items: stretch;
    overflow-y: scroll;
    flex-grow: 1;
    margin-left: 0px;
    width: 680px;
  }

  .job-list {
    display: grid;
    grid-template-columns: 45% 45%;
    place-content: start;
    gap: 16px;
    align-items: stretch;
    overflow-y: scroll;
    flex-grow: 1;
    margin-left: 0px;
    height: 100%;

    .row {
      width: 100%;
    }
  }

  .page {
    width: 100%;
    height: calc(100% - 50px);
    background: $white;
    overflow: auto;
    background: $light-grey;
    padding-bottom: 72px;
  }
}

.loader {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-position: center;
}

.buttonContainerForLoadMore {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px 0px;
}

.right-section {
  min-width: 300px;
  height: 520px;
  margin-right: 40px;
  border-radius: 6px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 20px;

  div:nth-of-type(1) {
    margin-top: 8px;
  }

  .text {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 0px;
    border-bottom: 1px solid #cdcdcd;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > span:nth-of-type(2) {
      min-width: 20px;
      display: inline-block;
      min-height: 20px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background: #d8d8d8;
      font-size: 12px;
      text-align: center;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .active-priority-tab {
    color: #000000;

    & > span:nth-of-type(2) {
      background: #000000;
      color: #fff;
    }
  }
}

.back-and-filters-container {
  display: flex;
  align-items: center;
}

.brandinfo-back-button {
  position: relative;
  cursor: pointer;
  padding: 0px 20px;
}

.brandinfo-back-button::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 20px;
  cursor: pointer;
  height: 20px;
  background-position: center;
  background: url("./images/arrow-left.svg") no-repeat;
  background-size: contain;
}

.map-switch {
  width: 30px;
  height: 30px;
  border-radius: 25px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-image: url("./images/map-icon-uniview.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.list-switch {
  @extend .map-switch;
  background-image: url("./images/list-icon-uniview.svg");
}

.pop-up {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 10%;
  bottom: 5%;
  z-index: 1;
}

.geo-tracker-toggle {
  margin-right: 20px;
  display: flex;
  align-items: center;

  .geo-tracker-toggle-label {
    margin-left: 10px;
  }
}

.enable-tracking-button {
  margin-left: 10px;
}

.disable-tracking-button {
  margin-left: 10px;
}

.enable-tracking-button::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 7px;
  width: 20px;
  cursor: pointer;
  height: 20px;
  background-position: center;
  background: url("./images/location-on.svg") no-repeat;
  background-size: contain;
}

.disable-tracking-button::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 7px;
  width: 20px;
  cursor: pointer;
  height: 20px;
  background-position: center;
  background: url("./images/location-off.svg") no-repeat;
  background-size: contain;
}

.calling-dashboard-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.de-activation-operation-container {
  display: flex;
  // padding: 20px;
  overflow: hidden;
  background: #f5f5f5;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  flex-direction: column;

  .process-container-label {
    margin: 0;
    // padding: 20px;
    // background: #fff;
    color: #666;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    padding: 0px 20px;

    .arrow-up {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background: url("./images/accordian-arrow.svg") no-repeat;
      background-position: center;
      transform: rotate(90deg);
    }

    .arrow-down {
      @extend .arrow-up;
      transform: rotate(270deg);
    }
  }

  .operation-resource-container {
    height: auto;
    overflow: auto;
    width: 100%;
    padding: 10px;
    border-top: none;
    border: solid 1px #d4dadf;

    .operation-resource {
      border-radius: 4px;
      margin-bottom: 15px;
      background: #fff;
      padding: 0px 10px 10px 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .initials-status-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .operation-resource-label {
          width: 100%;
          margin-top: 0;
          cursor: pointer;
          display: flex;
          align-items: center;

          .failed-duties-error {
            font-size: 12px;
            color: #ff2747b6;
            margin-left: 10px;
          }
        }

        .summary-container {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          // flex-direction: column;

          .mark-as-read {
            color: #ff435e;
            cursor: pointer;
            margin-left: 10px;
            font-size: 12px;
          }

          .summary-label {
            color: #36363692;
            font-size: 12px;
            flex-shrink: 0;

            .summary-value {
              margin-left: 10px;
            }
          }

          .progress-container {
            width: 100px;
            margin-left: 10px;
          }
        }
      }

      .resource-pipelines {
        display: flex;
        padding: 10px;
        background: #eceef0;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;

        &:last-of-type {
          margin-bottom: 0px;
        }

        .pipelines-label {
          margin: 0;
          font-size: 15px;
        }

        .status-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: auto;

          p {
            margin: 0;
            font-size: 12px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.calling-error {
  width: 100%;
  height: 80vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #36363698;
}

.dashboard-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666666;
  background: #efefef;
  padding: 5px 10px;
  border-radius: 20px;

  .calling-dashborad-dd {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &::before {
      content: "";
      border-radius: 50px;
      background-color: #00b893;
      width: 10px;
      height: 10px;
    }
  }

  .current-dashboard {
    margin: 0;
    font-size: 15px;
    color: #363636cc;
    display: flex;
    // margin-left: 10px;
    align-items: center;

    &::before {
      content: "";
      margin-right: 10px;
      border-radius: 50px;
      background-color: #00b893;
      width: 10px;
      height: 10px;
    }
  }
}

.status-container-base {
  width: 100%;
  height: auto;
  padding: 10px 0px;
  // position: sticky;
  // top: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  position: fixed;
  top: 120px;
  box-shadow: 0px 3px 6px #00000014;

  .list-container {
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
    max-width: calc(85vw - 30px);
    padding: 0px 32px 0px 10px;
    border-left: 1px solid #d4dadf;
    border-right: 1px solid #d4dadf;
    overflow: hidden;
  }
}

.scroll {
  max-width: 80%;
  overflow-x: auto;
}

.heading {
  font-size: 12px;
}

.flex {
  display: flex;
  padding: 0 10px;
}

.value {
  cursor: pointer;
  color: #363636;
  font-size: 12px;
  letter-spacing: 0px;
  padding: 8px 10px;
  height: 28px;
}

.count {
  margin-left: 5px;
  font-size: 12px;
  border-radius: 4px;
  width: 30px;
  height: 16px;
  text-align: center;
  padding: 0 5px;
}

.left-arrow {
  width: 16px;
  height: 16px;
  background-color: #ddd;
  color: #000;
  border-radius: 50%;
  background-image: url("./images/forward.svg");
  background-position: center;
  background-size: cover;
}

.selected {
  color: #fd3753;
  background: #ffe8eb 0% 0% no-repeat padding-box;
  border: 1px solid #fd3753;
  border-radius: 6px;
  font-size: 12px;
  letter-spacing: 0px;
  padding: 6px 10px;
  height: 28px;
  cursor: pointer;
}

.filters-funnel {
  background: url("./images/filters_assigned.svg") no-repeat;
  background-position: top;
  background-size: cover;
  width: 20px;
  height: 20px;
  margin: 0 20px 0 10px;
  cursor: pointer;
}

.import-icon {
  background: url("./images/leadImport-green.svg") no-repeat;
  background-position: top;
  background-size: cover;
  width: 25px;
  height: 25px;
  margin: 0 20px 0 10px;
  cursor: pointer;
}

.floating-toolbar-container {
  background: #fff;
  width: 91%;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0px 3px 6px #00000014;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toolbar-info-container {
    display: flex;
    width: inherit;
    align-items: center;

    p {
      margin: 0;
      color: #666666;
      font-size: 15px;
    }

    .selected-leads-number {
      margin: 0 10px;
      font-size: 18px;
      color: #00b893;
      font-weight: bold;
    }

    .assignees-container {
      width: 20%;
      margin: 0 10px;
    }
  }

  .toolbar-action-container {
    display: flex;
    align-items: center;

    .select-all-leads {
      font-size: 12px;
      font-weight: bold;
      color: #00b893;
      cursor: pointer;
      // &::after {
      //   content: "|";
      //   width: 1px;
      //   height: 20px;
      //   margin: 0 10px;
      //   background-color: #ddd;
      // }
    }

    .clear-leads {
      font-size: 12px;
      font-weight: bold;
      color: #fd3753;
      cursor: pointer;
      margin-left: 15px;
    }
  }
}

.tab-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;

  .text {
    padding-left: 24px;
    padding-right: 24px;
    align-self: stretch;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
  }

  .active-tab {
    border-bottom: 4px solid #ff435e;
    color: #363636;
    font-weight: 500;
  }
}

.process-refresh-button {
  width: 18px;
  height: 20px;
  transform: rotate(140deg);
  background-image: url("./images/refresh.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-right: 20px;
}

.second-layer {
  width: 100%;
  height: 60px;
  // border-top: 1px solid #d3dae0;
  border-bottom: 1px solid #d3dae0;
  background: #f4f6f8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  top: 120px;
}

.sortby-funnel {
  background: url("./images/sort-black.svg") no-repeat;
  background-position: center;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin: 0 20px 0 10px;
  cursor: pointer;
}

.sortby-arrow-holder {
  padding: 5px;
  display: flex;
  justify-content: flex-end;

  .sortby-arrow {
    width: 20px;
    height: 20px;
    background: url("./images/sort-up.svg") no-repeat;
    background-position: center;
    background-size: cover;
  }

  .sortby-arrow-down {
    @extend .sortby-arrow;
    transform: rotate(180deg);
  }
}

.toggle-buttons {
  // border: 1px solid #adadad;
  width: 200px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgb(0, 184, 147);

  .button {
    width: 100px;
    font-size: 12px;
    border-radius: 25px;
    padding: 8px;
  }
}
.addAccount-button {
  width: auto;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.account-option-container {
  display: flex;
  gap: 16px;
  align-items: center;

  .initial {
    width: 24px;
    height: 24px;
    background: #48546a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
  }

  .account-label {
    color: rgba(54, 54, 54, 0.6);
    font-size: 10px;
    font-weight: 500;

    .account-value {
      font-size: 12px;
      font-weight: 400;
      color: #363636;
    }
  }
}
.account-assignee-conatiner {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.show-account-header {
  display: flex;
  justify-content: space-between;
  .cross-icon {
    width: 20px;
    height: 20px;
    background: url("./images/cancelBlack.svg") no-repeat;
    background-position: center;
    background-size: 20px;
    cursor: pointer;
  }
}
.fx {
  display: flex;
}
.gap-10 {
  gap: 10px;
}
.fx-center {
  align-items: center;
}
.ml-10 {
  margin-left: 10px;
}
.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}
.h-34 {
  height: 34px;
}
.watch-button {
  width: auto;
  background: #ffffff;
  border: 1px solid #ebeef0;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}
.watch-button-active {
  composes: watch-button;
  background: #e5f7f3;
  box-shadow: 0px 1px 6px #00000029;
  border: 1px solid #0cb88f;
}
.partner-card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 85vw;
  padding: 20px 10px;
}
