@import "./variables.scss";
.base {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 80px);
}
.header {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d3dae0;
  border-bottom: 1px solid #d3dae0;
  background: #f4f6f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}
.content {
  width: 100%;
  max-width: 85vw;
  padding: 0 20px 20px;
  background: $white;
  border: 1px solid #d3dae0;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  max-height: 70vh;
}
.header .content {
  background: $header-background;
  padding: 0;
  padding: 0 20px;
  border: none;
}
.leaf-holder {
  width: 100%;
  margin-top: 20px;
  text-transform: capitalize;
}
.leaf {
  width: auto;
  height: 30px;
  border-radius: 15px;
  color: $black2;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  padding: 5px;
  padding-right: 30px;
}
.arrow {
  width: 10px;
  height: 10px;
  background: url("./images/arrow.svg") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  transition: 200ms;
  margin: auto;
}
.arrow-active {
  composes: arrow;
  transform: rotate(180deg);
}
.initials {
  width: 20px;
  height: 20px;
  color: $white;
  text-align: center;
  border-radius: 50%;
  line-height: 20px;
  margin-right: 5px;
}
.leaf-children {
  padding-left: 30px;
  position: relative;
}
.role {
  font-size: 10px;
  color: $black3;
  font-weight: normal;
  margin-left: 5px;
}
.border {
  width: 1px;
  height: calc(100% + 35px);
  position: absolute;
  left: 15px;
  top: -15px;
}
.border::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: brown;
}
.sales-administrator-border {
  composes: border;
  background: #ffb7c3;
}
.sales-administrator-border::after {
  background-color: #ffb7c3;
}
.search-bar {
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}
.sales-administrator {
  composes: leaf;
  background: #f7e5e8;
}
.sales-administrator .initials {
  background: #ffb7c3;
}
.sales-administrator:after {
  content: "";
  width: 1px;
  background: #ffb7c3;
  height: 100%;
  position: absolute;
  left: 15px;
  top: 25px;
}
.sales-head {
  composes: leaf;
  background: #e5ebf7;
}
.sales-head-border {
  composes: border;
  background: #c3d6fc;
}
.sales-head-border::after {
  background-color: #c3d6fc;
}
.sales-head .initials {
  background: #c3d6fc;
}
.sales-head:after {
  content: "";
  width: 1px;
  background: #c3d6fc;
  height: 100%;
  position: absolute;
  left: 15px;
  top: 25px;
}
.city-head {
  composes: leaf;
  background: #e5f7ec;
}
.city-head-border {
  composes: border;
  background: #afeac6;
}
.city-head-border::after {
  background-color: #afeac6;
}
.city-head .initials {
  background: #afeac6;
}
.city-head:after {
  content: "";
  width: 1px;
  background: #afeac6;
  height: 100%;
  position: absolute;
  left: 15px;
  top: 25px;
}
.default {
  composes: leaf;
  background: #eee7f8;
}
.default-border {
  composes: border;
  background: #7822c0;
}
.default-border::after {
  background-color: #7822c0;
}
.default .initials {
  background: #7822c0;
}
.default:after {
  content: "";
  width: 1px;
  background: #7822c0;
  height: 100%;
  position: absolute;
  left: 15px;
  top: 25px;
}
.sales-area-manager {
  composes: leaf;
  background: #f7f1e5;
}
.sales-area-manager-border {
  composes: border;
  background: #ead5ab;
}
.sales-area-manager-border::after {
  background-color: #ead5ab;
}
.sales-area-manager .initials {
  background: #ead5ab;
}
.sales-area-manager:after {
  content: "";
  width: 1px;
  background: #ead5ab;
  height: 100%;
  position: absolute;
  left: 15px;
  top: 25px;
}
.leaf:last-child:after {
  display: none;
}
.resource-hierarchy-holder {
  width: 300px;
}
.footer {
  width: 100%;
  background: $light-grey;
  padding: 12px 30px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
}
