@import "./variables.scss";

.base {
  width: 100%;
  height: auto;
  background: $white;
  position: relative;

  .header-container {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d3dae0;

    .header-text {
      font-size: 16px;
      font-weight: 500;

      .count {
        padding: 2px 8px;
        background: #d9e1e6 0% 0% no-repeat padding-box;
        border: 1px solid #eff2f4;
        border-radius: 16px;
        color: #5b6164;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;

    .tag-container {
      display: flex;
      align-items: center;
      margin: 10px 0px;
      padding: 0px 10px;
      gap: 10px;
      overflow-x: auto;
    }

    .tag-container::-webkit-scrollbar {
      display: none;
    }

    .tag {
      padding: 5px 20px;
      background: #ececec;
      border-radius: 15px;
      font-size: 12px;
      font-weight: 500;
    }

    .tag-active {
      @extend .tag;
      background: #fd3753;
      color: #fff;
    }
  }
}

.icon {
  composes: base;
  position: absolute;
  left: 0;
  top: 0;
  background: url("./images/bell.svg") no-repeat;
  background-size: 20px;
  background-position: center;
  cursor: pointer;
}

.notification-count {
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  background: $red;
  color: #fff;
  padding: 0 5px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  right: -5px;
  top: -5px;
}

.notifications-list {
  width: 300px;
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  position: absolute;
  top: 40px;
  right: 0px;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
}

.notification {
  width: 100%;
  min-height: 40px;
  padding: 15px;
  border-bottom: 1px solid $light-grey;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.notif-parent {
  display: flex;
  gap: 10px;
  align-items: center;
}
.clear-notif {
  border: 1px solid #363636;
  border-radius: 15px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 700;
  // letter-spacing: 0.22px;
  color: #363636;
  margin-right: 10px;
  cursor: pointer;
}

.message {
  font-size: 13px;
  margin-top: 5px;
  font-weight: normal;
  word-break: break-word;
  line-height: 20px;
}

.check-button {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  border: 1px solid $card-border;
  border-radius: 50%;
  background: $light-grey;
  cursor: pointer;
  transition: 300ms;
  flex-shrink: 0;
}

.check-button-active {
  composes: check-button;
  border: 1px solid $green;
  background: $green2;
  pointer-events: none;
}

.loading {
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-time {
  font-size: 13px;
  font-weight: normal;
  word-break: break-word;
  line-height: 20px;
  color: $grey;
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #1976d2;
  border-right: 10px solid #0c9675;
  border-bottom: 10px solid #ff435e;
  border-left: 10px solid #ffc6ce;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
