@import "../variables.scss";
.react-datepicker__input-container input {
  height: 40px;
  padding: 0 40px 0 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.react-datepicker__input-container input:focus {
  outline: none;
  border: 1px solid #787878;
}

.react-datepicker__input-container:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  background: url("../images/date.svg") no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 0;
  pointer-events: none;
  margin: auto;
}
.base {
  width: 100%;
  position: relative;
}
.base > div {
  display: block;
}
.base input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 15px;
  border: 1px solid $grey;
  border-radius: 4px;
  position: relative;
}
.baseRadius {
  @extend .base;
}
.baseRadius input {
  width: 100%;
  height: 30px;
  padding: 0 40px 0 15px;
  border: 1px solid $grey;
  border-radius: 15px;
  position: relative;
}
.baseErr {
  composes: base;
}
.baseErr input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 15px;
  border: 1px solid $red;
  border-radius: 4px;
  position: relative;
}
.base:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 0;
  background: url("../images/date.svg") no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 0;
  pointer-events: none;
  margin: auto;
}

.base input:focus {
  outline: none;
  border: 1px solid #787878;
}
.errorMsg {
  font-weight: 400;
  font-size: 12px;
  color: $red;
  padding: 5px;
  margin-bottom: 15px;
  text-align: right;
  position: absolute;
  right: 0px;
}
.placeholder {
  width: auto;
  height: auto;
  left: 10px;
  bottom: 0;
  margin: auto;
  position: absolute;
  top: -2px;
  display: flex;
  color: $black4;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  transform-origin: left top;
  align-items: center;
  font-size: 10px;
}
.placeholderText {
  background: $white;
  padding: 0 10px;
}
.place {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0px, -50%);
  font-size: 14px;
  color: #000000cc;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  background: $white;
  max-width: 82%;
}
select {
  height: 30px;
}
.required {
  color: $red;
  font-size: 14px;
  font-weight: bolder;
}
.cross {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 20px;
  height: 20px;
  transform: translate(0px, -50%);
}
.crossIcon {
  composes: cross;
  background: url("../images/cancelBlack.svg") no-repeat;
  background-position: center;
  cursor: pointer;
}
.borderClass input {
  @extend .base;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 30px;
  font-size: 11px;
}
.borderClass2 input {
  @extend .base;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 34px;
  font-size: 11px;
}
