@import "./variables.scss";

.base {
  padding: 20px 70px;
  height: calc(100vh - 60px);
}
.content {
  background-color: #ffffff;
  display: flex;
  color: #363636;
  border-radius: 6px;
  height: 100%;
}
.details-container {
  width: 70%;
  height: 100%;
  overflow: auto;
}
.title-container {
  display: flex;
  padding: 15px 50px 10px;
  align-items: center;
  box-shadow: 3px 2px 4px #f5f5f5;
}
.user-image {
  width: 30px;
  height: 30px;
  background: url("./images/account-circle.svg") no-repeat;
  background-position: center;
  background-size: contain;
}
.meeting-container {
  width: 30%;
  background-color: #faf9f9;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
}
.title {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 500;
}
.card-main-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 50px;
  padding: 20px 0px 20px 50px;
}
.card-title-holder {
  display: flex;
  align-items: center;
  .facebook {
    width: 30px;
    height: 30px;
    background: url("./images/facebook.png") no-repeat;
    background-position: center;
    background-size: cover;
  }
  .email {
    @extend .facebook;
    background-image: url("./images/mailIcon.svg");
  }
  .instagram {
    @extend .facebook;
    background-image: url("./images/instagram.png");
  }
  .linkedin {
    @extend .facebook;
    background-image: url("./images/linkedin.png");
  }
  .playstore {
    @extend .facebook;
    background-image: url("https://hood-cdn.nobrokerhood.com/hood-static-contents/email/img/google-play.png");
  }
  .twitter {
    @extend .facebook;
    background-image: url("https://hood-cdn.nobrokerhood.com/hood-static-contents/email/img/twitter.png");
  }
  .card-title {
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
  }
}
.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 42px);
}
.mv-10 {
  margin: 10px 0px;
}
.wh-20 {
  width: 20px !important;
  height: 20px !important;
}
.p-10 {
  padding: 10px;
}
.selected-shadow {
  box-shadow: rgba(0, 184, 147, 0.35) 0px 5px 15px;
  border-radius: 12px;
}
