@import "../variables.scss";
.base {
  width: 100%;
  border: 1px solid rgba($color: $black4, $alpha: 0.3);
  border-radius: 4px;
  padding: 1px;
  transition: 200ms;
  position: relative;
  font-size: 14px;
  font-weight: normal;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}
.base-active {
  composes: base;
  border: 1px solid rgba($color: $black4, $alpha: 0.3);
}
.base-error {
  composes: base;
  border: 1px solid $red;
}
.base-active-error {
  composes: base;
  border: 1px solid rgba($color: $red, $alpha: 0.3);
}
.base-hollow-error {
  composes: base;
  border: none;
  border-bottom: 1px solid red;
}
.base-hollow {
  composes: base;
  border: none;
  border-bottom: 1px solid $black;
}

.base-hollow-active {
  composes: base;
  border: none;
  border-bottom: 1px solid $black;
}

.input-actual {
  width: 100%;
  height: 100%;
  outline: none;
  padding: 5px 15px;
  border: none;
  font-size: 14px;
}
.base-hollow .input-actual {
  padding: 0;
  padding-top: 5px;
}
.input-actual:focus {
  outline: none;
  border: none;
}
.placeholder {
  margin: auto;
  position: absolute;
  top: 10px;
  display: flex;
  color: $black4;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  transform-origin: left top;
  align-items: center;
}
.placeholder-text {
  background: $white;
  padding: 0 10px;
}
.placeholder-active {
  composes: placeholder;
  transform: scale(0.8) translate(15px, -50%);
  color: $black4;
  top: 0;
}
.base-hollow .placeholder-active {
  transform: scale(0.85) translate(0px, -55%);
  opacity: 0.7;
  color: rgba($color: $black4, $alpha: 0.6);
}
.base-hollow .placeholder-text {
  padding: 0;
}
.error-help {
  color: red;
  padding-top: 4px;
  font-size: 12px;
}
textarea[disabled] {
  background: $grey7;
  cursor: not-allowed;
  padding: 8px;
}
.placeholder-disabled {
  background: $grey7;
  padding: 0 10px;
}
.character-count {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-weight: 500;
  font-size: 12px;
}
