@import "./variables.scss";

.main-container {
  border: 3px solid #e9e9ea;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.title-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid #ededed;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
}
.title {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}
.card-container {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
}
.card-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.card-title {
  font-size: 16px;
  color: #757a89;
}
.card-total {
  font-size: 18px;
  font-weight: 600;
}
.card-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.completed-value {
  width: 40px;
  font-size: 8px;
  transition: font-size 0.3s, width 0.3s;
}
.completed-value:hover {
  width: 75px;
  font-size: 15px;
  cursor: pointer;
}
.bar-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  min-height: 120px;
}
.bar-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.progress-container {
  height: 10px;
  border-radius: 3px;
  background: #daecef;

  .progress-bar {
    width: 0;
    height: 100%;
    border-radius: 3px;
    background: #44a2b0;
    animation: widthAnimation 1.5s ease forwards;
  }
  @keyframes widthAnimation {
    0% {
      width: 0; /* Start with 0 width */
    }
  }
}
.pie-color {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: #44a2b0;
}
.pie-label {
  text-transform: capitalize;
}
