@import "../variables.scss";

.single-base {
  width: 100%;
  height: auto;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.single-row {
  height: 15px;
  display: flex;
  align-items: center;
  // padding: 10px 10px 10px 0px;
  flex-shrink: 0;
  margin: 10px;
  width: calc(50% - 20px);
}

.button {
  width: auto;
  position: relative;
  padding-top: 15px;
  display: inline-block;
  min-width: 18px;
  cursor: pointer;
  margin-right: 10px;
}

.button:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 4px;
  border: 2px solid $grey8;
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: 300ms;
}

.button:after {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 4px;
  border: 1px solid $green;
  background: url("../images/check-mark.svg") no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 300ms;
  background-position: center;
  background-size: 10px;
  cursor: pointer;
}

.button-active {
  composes: button;
}

.button-active.button:after {
  opacity: 1;
}

.button-active.button:before {
  opacity: 0;
}

.button-disable {
  @extend .button;
  background: $grey8;
}

.button-disable.button:after {
  opacity: 0;
}

.button-disable.button:before {
  opacity: 1;
  background: $grey8;
}

.label {
  font-size: 13px;
  font-weight: normal;
  margin-top: 3px;
  color: $grey8;
}

.error {
  bottom: -16px;
  font-size: 11px;
  font-weight: normal;
  // color: $primary-color;
  padding: 5px;
  margin-bottom: 15px;
  text-align: right;
}
.display-label {
  font-weight: 500;
  color: #fff;
  background-color: #ff435e;
  border-radius: 20px;
  margin-left: 5px;
  padding: 2px 8px;
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 12px;
}
.designation {
  margin-left: 10px;
  text-wrap: nowrap;
  font-size: 12px;
}
