@import "../../variables.scss";
.base {
  width: 100%;
  height: auto;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}
.selected {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: 1px solid $grey;
  border-radius: 3px;
  background: $white;
  align-items: center;
  color: $black;
  display: flex;
}
.selcted-text {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchInput {
  width: 100%;
  height: 40px;
}
.searchInput input {
  width: 100%;
  height: 38px;
  border: none;
  text-decoration: none;
  -webkit-appearance: none;
  outline: none;
  vertical-align: top;
}
.dropDown {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  top: 40px;
  font-size: 12px;
  left: 0;
  z-index: 3;
  border: 1px solid $light-grey;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: $white;
}
.dropDown::-webkit-scrollbar {
  display: none;
}
.selectedError {
  composes: selected;
  border: 1px solid $red;
}
.dropDownTop {
  composes: dropDown;
  top: 0;
  transform: translateY(-100%);
}
.dropDownAutoSuggest {
  composes: dropDown;
  background: $white;
  top: 0;
}
.option {
  width: 100%;
  min-height: 40px;
  line-height: 16px;
  cursor: pointer;
  background: $white;
  border-bottom: 1px solid $light-grey;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.multiOption {
  width: 100%;
  min-height: 40px;
  line-height: 16px;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  align-items: center;
}
.optionHighlight {
  composes: option;
  background: $hover-color;
}
.option:hover {
  background: $hover-color;
  color: $black;
  transition: 300ms;
}
.placeHolder {
  color: $black4;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
}
.placeholder-active {
  @extend .placeHolder;
  position: absolute;
  top: -10px;
  padding: 0px 5px;
  background: #fff;
}
.disabled {
  composes: selected;
  cursor: not-allowed;
  background: $grayBg;
}
.downarrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 10px;
  width: 10px;
  height: 10px;
  background: url("../../images/down-arrow.svg") no-repeat;
  background-position: center;
  background-size: contain;
}
.downArrow {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 10px;
  height: 20px;
  transform: translate(0px, -50%);
}
.cancel-icon {
  composes: downarrow;
  background: url("../../images/cancelBlack.svg") no-repeat;
  background-position: center;
}
.down-arrow-disable {
  composes: downarrow;
  cursor: not-allowed;
  opacity: 0.5;
}
.input-holder {
  width: 100%;
  padding: 5px;
}
.input {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: $white;
}
.error {
  font-weight: 400;
  bottom: -16px;
  font-size: 11px;
  color: $primary-color;
  padding: 5px;
  margin-bottom: 15px;
  text-align: right;
}
.error-message {
  font-weight: 400;
  font-size: 10px;
  color: #ff435e;
  padding: 5px;
  /* margin-bottom: 15px; */
  text-align: right;
  position: absolute;
  right: 0px;
  bottom: -20px;
}
.inputHolder {
  width: 100%;
  padding: 5px;
}
.selectedGreen {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: 1px solid $green;
  border-radius: 3px;
  background: $white;
  align-items: center;
  color: $gray-lighter;
  display: flex;
}
.toggle-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  justify-content: flex-end;
}
.toggle-label {
  font-weight: normal;
  font-size: 10px;
  color: $black;
  padding-left: 10px;
}
