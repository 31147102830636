.base {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.inner-text {
  font-size: 1px;
  font-weight: normal;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
}
.total-text {
  font-size: 1px;
  font-weight: normal;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
}
.label {
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  text-align: left;
  max-width: 80%;
}
.sub-label {
  @extend .label;
  margin-top: 2px;
  font-weight: normal;
  font-size: 10px;
  color: #747373;
  max-width: 86px;
  text-align: center;
  text-overflow: clip;
  overflow: visible;
  white-space: break-spaces;
}
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 0;
  }
}
.circle-progress {
  stroke-dashoffset: 0;
  stroke: red;
  animation: drawCircle 1.5s ease forwards;
}
.circle-text {
  font-size: 4px;
  fill: #44a2b0;
}
.circle-text-ratio {
  color: #44a2b0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
