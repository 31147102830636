@import "./variables.scss";

.card-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-radius: 4px;
  background-color: #ffffff;
  border-top: 5px solid #64d92a;
  padding: 15px;
  box-shadow: 0px 1px 6px #ad9c9c;
}
.card-description {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-time {
  display: flex;
  column-gap: 5px;
  color: #9ea1a9;
  font-weight: 400;
}

.timer-icon {
  width: 20px;
  height: 20px;
  background: url("./images/time-darker.svg") no-repeat;
  background-position: center;
  background-size: contain;
}
.video-icon {
  width: 27px;
  height: 27px;
  background: url("./images/videoCall.svg") no-repeat;
  background-position: center;
  background-size: contain;
}
.meeting-icon {
  width: 27px;
  height: 27px;
  background: url("./images/invitetogroupS.svg") no-repeat;
  background-position: center;
  background-size: contain;
}
