@import "../variables.scss";
.base {
  width: 100%;
  height: 40px;
  border: 1px solid $grey;
  transition: 200ms;
  position: relative;
  font-size: 14px;
  border-radius: 4px;
  padding: 2px;
}
.base-err {
  composes: base;
  border: 1px solid $red;
}
.content {
  width: 100%;
  height: 100%;
  position: relative;
}
.base-active {
  composes: base;
  border: 1px solid $grey;
}
.base-active-err {
  composes: base;
  border: 1px solid $red;
}
.base-hollow {
  composes: base;
  height: 35px;
  border: none;
  border-bottom: 1px solid $black;
}
.base-hallow-err {
  composes: base;
  border: 1px solid $red;
}
.base-hollow-active {
  composes: base-hollow;
  border: none;
  border-bottom: 1px solid $black;
}
.input-actual {
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 15px;
  border: none;
  font-size: 14px;
}
.base-hollow .input-actual {
  padding: 0;
}
.input-actual:focus {
  outline: none;
  border: none;
}
.placeholder {
  height: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  top: 0;
  display: flex;
  color: $black4;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  transform-origin: left top;
  align-items: center;
}
.placeholder-text {
  background: $white;
  padding: 0 10px;
}
.placeholder-active {
  composes: placeholder;
  transform: scale(0.8) translate(15px, -50%);
  color: $black4;
}
.placeholder-active-disabled {
  composes: placeholder;
  transform: scale(0.8) translate(15px, -50%);
  color: $black4;
  background: $grey7;
}
.base-hollow .placeholder-active {
  transform: scale(0.85) translate(0px, -55%);
  opacity: 0.7;
  color: rgba($color: $black4, $alpha: 0.6);
}
.base-hollow .placeholder-text {
  padding: 0;
}
.errorMsg {
  font-weight: 400;
  font-size: 12px;
  color: $red;
  padding: 5px;
  margin-bottom: 15px;
  text-align: right;
  position: absolute;
  right: 0px;
}
.password-hidden {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-50%, -50%);
  background: url("../images/visibility.svg") no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
}
.password {
  @extend .password-hidden;
  background: url("../images/visibility_hidden.svg") no-repeat;
  background-position: center;
  background-size: contain;
}
.required {
  color: $red;
  font-size: 14px;
  font-weight: bolder;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[disabled] {
  background: $grey7;
  cursor: not-allowed;
  width: 100%;
  height: 100%;
  border: none;
  padding: 8px;
}
.placeholder-disabled {
  background: $grey7;
  padding: 0 10px;
}
.cross {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 20px;
  height: 20px;
  transform: translate(0px, -50%);
}
.crossIcon {
  composes: cross;
  background: url("../images/cancelBlack.svg") no-repeat;
  background-position: center;
  cursor: pointer;
}