@import "../components/variables.scss";

.base {
  width: 100%;
  height: auto;
  background: $light-grey;
  position: relative;
}

.content {
  width: 100%;
  height: auto;
  padding: 25px;
  background: $white;
  margin-top: 5px;
}

.fixed-content {
  padding: 15px 40px 15px 40px;
  position: relative;
  min-height: 60vh;
  max-height: calc(60vh - 125px);
  overflow: auto;
}

.content:first-child {
  margin: 0;
}

.row {
  width: 100%;
  margin-top: 25px;
}

.row:first-child {
  margin: 0;
}

.col {
  width: 48%;
  margin-top: 25px;
  position: relative;
}

.col48 {
  width: 48%;
}

.col50 {
  width: calc(50% - 20px);
  margin-bottom: 25px;
  position: relative;
}

.col66 {
  width: 66%;
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  padding-right: 10px;
  height: auto;
  margin-bottom: 10px;
}

.contact-base {
  background: $white;
  width: 100%;
}

.primary-contact {
  padding: 15px 40px 0px 40px;
  position: relative;
}

.primary-contact-footer-fixed {
  padding: 15px 40px 120px 40px;
  position: relative;
}

.heading {
  font-size: 12px;
  padding-bottom: 5px;
  color: $black4;
  font-weight: 400;
}
.heading-bold {
  font-weight: 500;
}

.table-heading {
  font-size: 16px;
  padding-bottom: 10px;
  color: #000000cc;
  font-weight: 500;
}

.table-heading-small-font {
  font-size: 14px;
  padding-bottom: 10px;
  color: #000000cc;
  font-weight: 500;
}

.input {
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  width: 32%;
  height: auto;
  padding: 2px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.input70 {
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  width: calc(68% - 8px);
  height: auto;
  padding: 2px;
  margin-bottom: 10px;
}

.col25 {
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  width: 23%;
  height: auto;
  padding: 2px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.col18 {
  @extend .col25;
  width: 18%;
}

.input-col {
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  width: 50%;
  padding-right: 10px;
  height: auto;
  margin-bottom: 10px;
}

.input-col48 {
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  width: 48%;
  height: auto;
  padding: 2px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.input-col-100 {
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;
  padding-right: 10px;
  height: auto;
  margin-bottom: 10px;
}

.locality {
  composes: input;
  width: 65%;
}

.address {
  composes: input;
  width: 100%;
}

.date-assingee-container {
  display: grid;
  column-gap: 20px;
  align-items: flex-end;
  grid-template-columns: 1fr 300px;
}

.job-due-date-input-container {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;

  .job-due-date-input-container-label {
    margin: 0;
    font-size: 15px;
    color: #adadad;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .job-due-options {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 80px 90px 1fr;
    align-items: center;

    .due-date-custom-option {
      height: 30px !important;
    }

    .due-date-option,
    .active-due-date-option {
      cursor: pointer;
      font-size: 12px;
      color: #0c9675;
      border-radius: 4px;
      padding: 6px;
      flex-shrink: 0;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #adadad;
    }

    .active-due-date-option {
      color: #fff;
      background-color: #0c9675;
      border: solid 1px #0c9675;
    }
  }
}

.input-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

// .loan-amount-container{
//   display: flex;
// }
.row-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.flex-space {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.flex-btw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
}

.file-upload {
  padding-top: 5px;
  position: relative;
}

.add {
  width: auto;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  user-select: none;
  color: $dark-blue;
}

.add-disabled {
  composes: add;
  opacity: 0.7;
  pointer-events: none;
}

.add:before {
  content: "+";
  width: 20px;
  height: 20px;
  background: $green2;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
  color: $white;
  font-size: 18px;
}

.header-text {
  font-size: 20px;
  font-weight: 500;
}

.view-jobs-modal-header-text {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.header {
  padding: 20px 40px;
  border-bottom: 1px solid $light-border;
  position: relative;

  .cross-icon {
    width: 26px;
    height: 26px;
    background: url("./images/cancel.svg") no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 10px;
    cursor: pointer;
    transform: translate(-10px, -50%);
  }
  .download-icon {
    width: 26px;
    height: 26px;
    background: url("./images/download.svg") no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 60px;
    cursor: pointer;
    transform: translate(-10px, -50%);
  }
}

.footer {
  background: $light-grey;
  padding: 12px 30px;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.footer-fixed {
  background: #f5f5f5;
  padding: 16px 30px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: 800px;
  bottom: 46px;
}

.notfication {
  padding-left: 40px;
}

.button {
  padding-left: 10px;
}

.contact-header {
  width: 100%;
  min-height: 54px;
  display: flex;
  align-items: center;
  background: $light-grey;
  font-size: 18px;
  color: $black;
  font-weight: 500;
  border-left: 4px solid $dark-blue;
  padding: 15px 30px 15px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
}

.contact-name {
  font-size: 12px;
  color: $grey;
  font-weight: normal;
}

.contact-value {
  font-size: 13px;
  color: $black;
  padding-top: 5px;
}

.col-flex {
  display: flex;
  flex-direction: column;
}

.del {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url("./images/delete-green.svg");
  top: 8px;
  right: 2px;
  cursor: pointer;
}

.primary-tag {
  padding: 4px 12px;
  background: #f5f5f5;
  text-align: center;
  margin: 10px 0px 5px 0px;
  width: fit-content;
  border-radius: 14px;
  font-size: 12px;
  float: right;
}

.editDetailsHolder {
  height: calc(80vh - 139px);
  overflow: auto;
}

.footerHolder {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.leadFiledHolder {
  margin-bottom: 70px;
}

.leadFooter {
  composes: footer;
  margin-top: 0;
}

.file-list {
  font-size: 12px;
  margin-right: 5px;
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-top: 3px;
  max-width: 120px;
  padding: 5px 20px;
  background-color: $grey7;
}

.file-list-mx {
  font-size: 12px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid #00b893;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 3px;
  max-width: 100%;
  padding: 5px 10px;
  color: #006551;
  font-weight: 500;
  background-color: white;
}

.file-list::before {
  content: "";
  background: url("./images/cancel.svg") no-repeat;
  background-position: top;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: 2px;
  width: 15px;
  height: 15px;
  opacity: 0.7;
  transform: translate(0px, -50%);
}

.sub-label {
  width: 100%;
  height: auto;
  font-size: 12px;
  color: "#626262";
  margin-top: 16px;
  letter-spacing: 0.11px;
  font-weight: normal;
}

.sub-label-no-margin {
  @extend .sub-label;
  margin-top: 10px;
}

.error {
  color: $red;
  padding-bottom: 4px;
  font-size: 12px;
}

.address-error {
  width: 100%;
  font-size: 12px;
  color: #36363678;
  text-align: center;
  margin-top: 20px !important;
}

.del2 {
  composes: del;
  top: -6px;
  left: 2px;
}

.import-holder {
  height: 60px;
  width: 30%;
  padding-right: 10px;
  display: inline-block;
}

.label {
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
}

.cancel {
  content: "";
  background: url("./images/cancel.svg") no-repeat;
  background-position: top;
  background-size: cover;
  position: absolute;
  width: 15px;
  height: 15px;
  opacity: 0.8;
  top: 50%;
  right: 40px;
  z-index: 999;
  cursor: pointer;
  transform: translate(0px, -50%);
}

.cancel:hover {
  // -webkit-transform: scale(1.6);
  // transform: scale(1.6) translate(0px, 0%);
}

.primary-holder {
  position: absolute;
  bottom: 12px;
  right: 110px;
}

.contacts-holder {
  position: relative;
  margin-top: 15px;
}

.lead-duplicate-form {
  padding: 15px 40px 0px 40px;
  position: relative;
}

.pad-bot100 {
  padding-bottom: 100px;
}

.container {
  width: 100%;
  height: auto;
  padding: 30px 20px;
}

.checkbox-container {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(10px, -50%);
}

.notes {
  position: absolute;
  top: -25px;
  left: 10px;
  color: $red;
  font-size: 14px;
  font-weight: 500;
}

.toggleHolder {
  display: flex;
  align-items: center;

  .toggle-label {
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.amount-dimension {
  visibility: visible;
  display: flex;
  flex-flow: row-reverse;
  position: absolute;
  right: 0;

  .amount-content {
    font-size: 12px;
    color: #00b893;
    font-weight: 500;
  }
}

.location-view {
  width: 100%;
  height: auto;
  padding: 10px 25px 10px 0px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: normal;
  position: relative;

  .edit-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    background: url("./images/editIcon-green.svg") no-repeat;
    background-size: cover;
    background-position: center;
    transform: translate(0px, -50%);
  }

  .cancel-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    background: url("./images/cancelBlack.svg") no-repeat;
    background-size: cover;
    background-position: center;
    transform: translate(0px, -50%);
  }
}

.input100 {
  position: relative;
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;
  height: auto;
  padding: 2px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.input-dimensions {
  visibility: visible;
  display: block;
  position: relative;
  border-radius: 4px;
  width: 32%;
  height: 5px;
}

.input-dimensions-hide {
  visibility: hidden;
  display: block;
  position: relative;
  border-radius: 4px;
  width: 32%;
  height: 5px;
}

.flex-jc-btw {
  @extend .header;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-with-button-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .dropdown-holder {
    width: 50%;
    margin-right: 10px;
  }
}

.input-dimensions-hide {
  visibility: hidden;
  display: block;
  position: relative;
  border-radius: 4px;
  width: 32%;
  height: 5px;
}

.edit-img {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url("./images/editIcon-green.svg");
  top: 5px;
  right: 45px;
  cursor: pointer;
}

.cancel-seller {
  width: 20px;
  height: 20px;
  background: url("./images/cancel.svg");
  cursor: pointer;
  background-repeat: no-repeat;
  margin-top: 5px;
  margin-right: 15px;
}

.save-seller {
  width: 20px;
  height: 20px;
  background: url("./images/check-mark.svg");
  cursor: pointer;
}

.edit-seller-container {
  display: flex;
  justify-content: flex-end;
}

.edit-del-container {
  display: flex;
  justify-content: flex-start;
}

.financial-toggle-container {
  display: flex;
  justify-content: space-between;
}

.no_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .no_image {
    width: 72px;
    height: 72px;
    background: url("./images/no_image.svg") no-repeat;
    background-position: center;
    background-size: cover;
  }

  .no_preview_heading {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #464646;
    margin-top: 18px;
  }

  .no_preview_sub_heading {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #888888;
    margin-top: 6px;
    margin-bottom: 16px;
    width: 262px;
  }
}

.chip {
  background-color: #eee;
  color: #444;
  font-weight: 300;
  font-size: 13px;
  padding: 6px 10px;
  margin: 3px;
  border-radius: 13px;
}

.bold-text {
  font-weight: 500;
  font-size: 16px;
}

.tag {
  padding: 2px 5px;
  background: $green;
  color: $white;
  border-radius: 15px;
  margin-right: 2px;
  font-size: 8px;
  font-weight: 500;
  text-transform: capitalize;
}

.title {
  width: 100%;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;

  .button-text {
    color: #0cb88f;
    margin-left: 60px;
    letter-spacing: 0.04px;
    cursor: pointer;
  }
}

.product-label {
  letter-spacing: 0.14px;
  color: #747373;
  width: 100%;
  margin-top: 10px;
  font-size: 11px;
}

.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  .holder {
    position: relative;
    margin-top: 10px;
    border-radius: 4px;
    width: 32%;
    height: auto;
    padding: 2px;
    margin-right: 8px;
    margin-bottom: 10px;

    .paragraph {
      color: #00000099;
      font-size: 12px;
      margin-top: 5px;
      font-weight: normal;
    }

    .label {
      width: 100%;
      font-size: 14px;
      color: #000000de;
      font-weight: 500;
    }
  }
}

.right-align-button-text {
  color: #0cb88f;
  margin-left: 60px;
  letter-spacing: 0.04px;
  float: right;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.load-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
}

.preview-img {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #ddd;
  margin: 5px 0px;
  position: relative;
}

.document-name {
  width: 76px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  font-size: 10px;
  font-weight: normal;
  text-align: center;
}

.document-heading {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.template-selector-base {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  .template-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    .template-img {
      width: 40px;
      height: 40px;
      background-image: url("./images/sendbrochure.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
      border: 1px solid #999;
      margin-bottom: 10px;
    }

    .template-name {
      font-size: 14px;
      font-weight: 500;
      color: #000000de;
      margin-bottom: 5px;
    }

    .template-count {
      font-size: 11px;
      font-weight: normal;
      margin-bottom: 5px;
      color: #464646;
    }

    .begin {
      color: #f83a69;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      padding-right: 20px;
      content: "Begin";
      cursor: pointer;
    }

    .begin::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 50%;
      right: 0px;
      transform: translate(0px, -50%) rotate(180deg);
      background: url("./images/backRed.svg") no-repeat;
      background-position: center;
    }

    .finish {
      color: #0cb88f;
      font-size: 14px;
      font-weight: 500;
      position: relative;
      padding-left: 20px;
      text-transform: capitalize;
      cursor: pointer;
    }

    .finish::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 0px;
      transform: translate(0px, -50%);
      background: url("./images/success.svg") no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}

.note-text {
  color: #0cb88f;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  padding-left: 20px;
}

.ps {
  font-size: 14px;
  font-weight: 500;
  color: #1976d2;
  position: absolute;
  bottom: 0;
}
.merge-note {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #1976d2;
}

.well {
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  background-color: #eae6ff;

  .header-text {
    color: #172b4d;
    font-size: 1.14286em;
    font-style: inherit;
    font-weight: 600;
    letter-spacing: -0.006em;
    line-height: 1.25;
  }

  .information {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857142857143;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    text-decoration-skip-ink: auto;
  }
}

.exprian-logo {
  width: 105px;
  height: 50px;
  background-image: url("./images/cibil-logo-lt.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.swap-icon {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, 0);
  background: url("./images/swap_horiz_black.svg") no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  background-size: cover;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.activity-file-name {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.23px;
  color: $black;
  width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.leads-error-message {
  font-weight: 400;
  font-size: 12px;
  color: #ff435e;
  padding-top: 5px;
  margin: 0;
  position: absolute;
  right: 0px;
}

.loan-details-base {
  width: 100%;
  height: calc(90vh - 134px);

  .loan-container {
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
  }

  .loan-footer {
    background: $light-grey;
    padding: 12px 30px;
    display: flex;
    justify-content: space-between;

    .button {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.button-holder {
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchbar-holder {
  width: 280px;
  height: 34px;
  margin-left: 20px;
}

.table-base {
  width: 100%;
  height: 500px;
}

.menu-icons {
  position: relative;
  font-size: 12px;
  letter-spacing: 0.15px;
  font-weight: 500;
  color: $black;
  padding-left: 25px;
}

.remove-leads-button {
  @extend .menu-icons;
  width: 18px;
  height: 18px;
  background: url("./images/cancelBlack.svg") no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.add-leads-button {
  @extend .menu-icons;
  width: 18px;
  height: 18px;
  background: url("./images/add-more.svg") no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.checkbox-max-width {
  width: 100%;
}

.agreement-docs-container {
  padding: 0 20px;
  display: grid;
  align-items: center;
  overflow: auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  height: 50%;
}

.agreement-doc {
  background: #ddd;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  margin: 0;
}

.tag {
  margin-top: 35px;
  display: flex;
  align-items: center;
  background-color: transparent;

  .file-name {
    background-color: $grey7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
    margin: 0;
    cursor: pointer;
    padding: 5px 20px;
    font-size: 12px;
    color: #000000de;
    border-radius: 50px;
    margin-right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .file-name:hover {
    text-decoration: underline;
  }

  .cancel-icon {
    background: url("./images/cancel.svg") no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
    top: 4px;
    right: 2px;
    width: 15px;
    height: 15px;
    opacity: 0.7;
  }
}

.scrollable-modal-content {
  overflow: auto;
  height: auto;
  padding: 25px;
  min-height: 40vh;
  background-color: #fff;
  max-height: calc(90vh - 188px);
}

.copy-icon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  background: url("./images/copy.svg") no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.success {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  background-image: url("./images/success.svg");
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
  text-overflow: ellipsis;
}

.otp-div {
  border-bottom: 1px solid #d3dae0;
  padding: 10px 0px;

  .header-title {
    font-size: 16px;
    padding: 4px 2px 0px;
    color: $black;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }

  .otp-info {
    padding: 2px;
    font-size: 14px;
    color: $red;
  }
}

.otp-container {
  @extend .otp-div;
  border: none;
  min-height: 220px;
}

.job-container {
  width: 100%;

  .job-validation {
    border-top: 1px solid #d3dae0;
    width: 100%;
    padding: 10px 0px;

    .job-validation-info {
      display: flex;
      flex-direction: row;

      .job-heading {
        color: #00000099;
        font-size: 14px;
        font-weight: normal;
        margin-top: 2px;
      }

      .job-description {
        color: #000000de;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 5px;
        width: 80%;
        align-items: center;
      }
    }
  }
}

.price-description {
  padding: 5px 0px;
  font-weight: 600;
  font-size: 16px;
  font-style: italic;
}
.price-tag {
  display: flex;
  gap: 5px;
  padding: 5px 0px;
  font-weight: 600;
  color: #808080;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.col30 {
  width: 30%;
  margin-top: 25px;
  position: relative;
}

.edit-button {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-label {
  font-size: 12px;
  margin-left: 5px;
  font-weight: normal;
  color: $black;
}

.email {
  font-weight: normal;
  font-size: 10px;
  color: #999;
}

.rolename {
  font-weight: 500;
}

.template-input-container {
  padding: 20px 40px;
}

.displaylabel {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
}

.partyname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rolename {
  font-weight: 500;
  color: #fff;
  background-color: #ff435e;
  border-radius: 20px;
  margin-left: 5px;
  padding: 2px 5px;
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
}

.new_rolename {
  font-weight: 500;
  color: #fff;
  background-color: #ffa000;
  border-radius: 4px;
  margin-left: 5px;
  padding: 2px 5px;
  white-space: nowrap;
  width: 80px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
}

.assigneeTitle {
  background-color: $green;
  color: $white;
  border-radius: 15px;
  margin-left: 2px;
  padding: 0px 5px;
  text-transform: capitalize;
}

.contacts-container {
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .contact {
    cursor: pointer;
    background: #f0f0f0;
    padding: 10px;
    border-radius: 6px;

    p {
      margin: 0;
    }

    .email {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  .selected-contact {
    cursor: pointer;
    background: #ddd;
    padding: 10px;
    border-radius: 6px;

    p {
      margin: 0;
    }

    .email {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}

.product-icon {
  display: flex;
  align-items: center;

  .icon {
    width: 60px;
    height: 25px;
    margin-right: 12px;
  }
}

.create-activity-container {
  padding: 20px;
  background: #f5f5f5;
  margin: 5px;
  font-size: 12px;
  color: rgba(54, 54, 54, 0.6);
  cursor: default;

  .account-create-button {
    position: relative;
    padding-left: 20px;
    font-size: 13px;
    font-weight: 500;
    color: #0cb88f;
    text-transform: capitalize;
    margin-top: 12px;
    cursor: pointer;
  }

  .account-create-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("./images/add-more.svg") no-repeat;
    background-position: center;
    background-size: contain;
    width: 16px;
    height: 16px;
  }
}

.new-account-added {
  color: #48546a;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  margin-top: 14px;
  padding-left: 24px;
  position: relative;
}

.new-account-added::before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("./images/check-mark.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.account-option-container {
  display: flex;
  gap: 16px;
  align-items: center;

  .initial {
    width: 24px;
    height: 24px;
    background: #48546a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
  }

  .account-label {
    color: rgba(54, 54, 54, 0.6);
    font-size: 10px;
    font-weight: 500;

    .account-value {
      font-size: 12px;
      font-weight: 400;
      color: #363636;
    }
  }
}

.content-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.link {
  color: $dark-blue;
  text-decoration: underline;
  cursor: pointer;
}

.content-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.flex-space-btw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.preview-container {
  width: 100%;
  height: auto;
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;

  .preview-img {
    width: 84px;
    height: 74px;
    border-radius: 2px;
    padding: 5px;
    border: 1px solid #ddd;
    margin-right: 5px;
    position: relative;
    margin-top: 15px;
  }

  .document-name {
    width: 76px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    font-size: 10px;
    font-weight: normal;
    text-align: center;
  }

  .document-subType {
    overflow-wrap: anywhere;
    margin-top: 2px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: #999;
  }
  .delete-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url("./images/delete.svg");
    background-color: $white;
    background-size: contain;
    background-position: center;
  }
}

.row-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.time-set {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  .time {
    color: #00b893;
    background-color: white;
    border-radius: 4px;
    padding: 0px 5px;
    width: 35%;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 30px;
    position: relative;
    display: flex;
    margin: auto;
    align-items: center;
    cursor: pointer;
  }

  .active-time {
    background-color: #00b893 !important;
    color: white;
    border-radius: 4px;
    padding: 0px 5px;
    width: 35%;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
    cursor: pointer;
  }

  .disable-tab {
    cursor: not-allowed;
    background-color: #ebebeb;
  }
}

.tab-label {
  @extend .label;
  font-size: 11px;
  padding: 3px 0px;
}

.paddingTopZero {
  padding-top: 0px;
}

.marginTop10 {
  margin-top: 10px;
}

.col20 {
  @extend .col;
  margin-top: 20px;
}

.row20 {
  @extend .row;
  margin-top: 20px;
}

.type-success-message {
  font-weight: 400;
  font-size: 12px;
  color: green;
  padding-top: 5px;
  margin: 0;
  position: absolute;
}

.flex-button {
  display: flex;
  gap: 10px;
  align-items: center;
}

.counter-container {
  width: 114px;
  height: 40px;
  border: 2px solid #0cb88f;
  border-radius: 6px;
  display: flex;

  .operand {
    width: calc(100% / 3);
    height: 100%;
    font-size: 14px;
    letter-spacing: 0.04px;
    color: #0cb88f;
    background: #edfcf8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .operator {
    @extend .operand;
    font-size: 18px;
    font-weight: 500;
    background: $white;
    letter-spacing: 0px;
    border-radius: 6px;
    cursor: pointer;
  }
}

.count-button {
  border: 2px solid #0cb88f;
  border-radius: 6px;
  padding: 11px 39px;
  letter-spacing: 0.04px;
  color: #0cb88f;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  width: 114px;
}

.activity-span {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 10px;
  color: green;
}

.type-failure-message {
  font-weight: 400;
  font-size: 12px;
  color: red;
  padding-top: 5px;
  margin: 0;
  position: absolute;
}

.main-container {
  display: flex;
  flex-direction: column;

  .resource-duties {
    // border-bottom: solid 1px #ddd;
    p {
      margin-top: 0;
    }

    .duties {
      .duties-details {
        display: flex;
        overflow-x: auto;
        padding-bottom: 10px;
        align-items: center;

        .duty-container {
          display: flex;
          align-items: center;
          margin-right: 10px;

          &:last-of-type {
            margin-right: 0;
          }

          .duty {
            padding: 10px;
            padding-left: 0;
            border-radius: 4px;

            .duty-name {
              color: #363636cc;
              font-size: 13px;
            }

            .duty-count {
              font-size: 15px;
              padding: 2px 7px;
              font-weight: bold;
              margin-left: 10px;
              background: #eee;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  .manager-container {
    margin-top: 20px;

    p {
      margin-top: 0;
    }

    .new-manager-container {
      display: flex;
      align-items: center;
      flex-direction: column;

      .current-manager {
        width: 100%;
        display: flex;
        align-items: center;

        .current-manager-key {
          font-size: 13px;
          flex-shrink: 0;
          color: #363636cc;
        }

        .current-manager-value {
          width: 100%;
          margin-left: 10px;
        }
      }

      .or {
        margin: 10px;
      }

      .manager-options {
        width: 100%;
        display: flex;
        justify-content: center;

        .close-button {
          padding: 5px;
          width: 10%;
          font-size: 12px;
          line-height: 18px;
          border-radius: 4px;
          border: solid 1px #eee;
          background-image: url("./images/cancel-red.svg");
          background-position: center;
          background-size: 15px;
          background-repeat: no-repeat;
          margin-left: 10px;
          margin-bottom: 0;
          cursor: pointer;
          user-select: none;
        }
      }
    }

    .de-activation-note {
      font-size: 12px;
      line-height: 2;
      margin-top: 10px;
      text-align: center;

      .m-duty {
        border-radius: 4px;
        padding: 2px 5px;
        background: #eee;
        margin-right: 5px;
      }
    }
  }
}

.confirm-text {
  color: $red;
  font-size: 15px;
  align-self: center;
  margin: auto auto auto 0;
}

.manager-name {
  color: $green2;
  font-size: 15px;
  margin: 0 5px;
  font-weight: bold;
}

.changed-manager-container {
  margin: 20px 0;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  .label {
    color: #ff435e;
    font-size: 13px;
    text-align: center;
    margin-bottom: 20px;
  }

  .changed-manager {
    display: flex;
    align-items: center;

    .old {
      padding: 10px;
      background: #eee;
      border-radius: 4px;
      color: $green2;
      font-size: 15px;
      font-weight: bold;
    }

    .arrow {
      width: 20px;
      height: 12px;
      background-image: url("./images/arrow-left.svg");
      background-position: center;
      background-size: 15px;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      margin: 0 20px;
    }

    .new {
      padding: 10px;
      background: #eee;
      border-radius: 4px;
      color: $green2;
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.editDetailsHolderMaxHeight {
  max-height: calc(80vh - 124px);
  overflow: auto;
}

.heading-left {
  font-size: 13px;
  text-align: right;
  color: green;
  font-weight: 400;
}

.draft-saved {
  font-size: 13px;
  color: #0c9675;
  font-weight: 500;
  padding-left: 20px;
  position: relative;
}

.draft-saved::before {
  content: "";
  width: 14px;
  height: 14px;
  background: url("./images/success.svg") no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.saving {
  font-size: 13px;
  color: $black;
  font-weight: 500;
  padding-left: 20px;
  position: relative;
}

.saving::before {
  content: "";
  width: 14px;
  height: 14px;
  background: url("./images/refresh.svg") no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.html-note-continer {
  padding: 10px;
  background-color: #ebebeb;
  border-radius: 5px;

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    height: 100%;

    li {
      line-height: 16px;
    }

    .inline-block {
      display: inline-block;
    }

    h4,
    h5,
    h6 {
      line-height: 16px;
      margin: 0px;
      padding: 0px;
      margin-right: 4px;
    }
  }
}

.html-preview {
  border: 1px solid #ececec;
  padding: 10px;
  margin-top: 10px;
}

.accordian-section {
  width: 100%;
  background-color: #ececec;
  border-radius: 5px;
  margin-top: 20px;

  .header {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .heading {
      color: #363636;
      font-size: 14px;
      font-weight: 500;
    }

    .up-icon {
      width: 20px;
      height: 20px;
      background: url("./images/down-arrow.svg") no-repeat;
      background-position: center;
      background-size: 16px;
      transition: transform 0.5s;
      transform: rotate(180deg);
    }

    .down-icon {
      @extend .up-icon;
      transform: rotate(0deg);
    }
  }
}

.show-note {
  font-size: 14px;
  color: #00b893;
  margin-left: 59%;
}

.cancellation-tip {
  font-size: 12px;
  color: red;
}

.cancel-jobs-label {
  font-size: 15px;
  margin: 0;
  flex-shrink: 0;
  color: #adadad;
}

.cancelled-jobs-container {
  margin-left: 20px;
  grid-gap: 20px;
  gap: 20px;
  width: 700px;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .job-to-be-cancelled {
    font-size: 12px;
    color: #00b893;
    border-radius: 4px;
    padding: 5px 8px;
    flex-shrink: 0;
    background-color: rgba(12, 184, 143, 0.1);
    border: solid 1px #00b893;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected-job-name {
      margin: 0 10px 0 0;
    }

    .remove-selected-job-action {
      cursor: pointer;
      width: 10px;
      height: 10px;
      background: url("./images/cancel-red.svg") no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}

.existing-jobs-container-small,
.existing-jobs-container-full {
  display: grid;
  align-items: center;
  grid-column-gap: 20px;

  .existing-jobs-dd-container {
    width: 100%;
  }
}

.existing-jobs-container-full {
  display: flex;
  margin-bottom: 12px !important;
  padding-bottom: 20px;
  border-bottom: solid 1px #ddd;
}

.existing-jobs-container-hidden {
  padding-bottom: 20px;
  margin-bottom: 12px !important;

  .hidden-header {
    display: inline-block;
    font-size: 15px;
    color: #363636cc;
    margin: 0;
    cursor: pointer;

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      padding: 10px;
      background-image: url("./images/down-arrow.svg");
      background-position: center;
      background-size: 8px;
      background-repeat: no-repeat;
      transition: transform 0.5s;
      transform: rotate(180deg);
    }
  }
}

.divider {
  display: flex;
  margin: 30px 0;
  align-items: center;
  justify-content: center;

  hr {
    border: solid 1px #ddd;
    width: 100%;
  }

  p {
    margin: 0 20px;
    color: #adadad;
  }
}

.primary-contact-flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 40px;
  position: relative;
  max-height: calc(90vh - 135px);
  min-height: calc(80vh - 135px);
  overflow: auto;
  margin-top: 20px;
}

.primary-contact-without-flex {
  padding: 15px 40px;
  position: relative;
  max-height: calc(90vh - 135px);
  min-height: calc(80vh - 135px);
  overflow: auto;
}

.filter-options-container {
  display: grid;
  padding: 12px 0;
  grid-template-columns: 60px 1fr;
  align-items: center;
  justify-content: space-between;

  .filter-options-container-label {
    margin: 0;
    font-size: 15px;
    color: #adadad;
  }

  .filters {
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: space-between;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    .filters-scrollable {
      display: flex;
      width: 100%;
      gap: 20px;
      align-items: center;
      justify-content: flex-end;

      .filter-option,
      .filter-option-selected {
        font-size: 12px;
        padding: 5px 8px;
        color: #adadad;
        cursor: pointer;
        flex-shrink: 0;
        border-radius: 4px;
        border: solid 1px #ddd;
      }

      .filter-option-selected {
        border: solid 1px #0c9675;
        color: #0c9675;
      }

      .filter-option-dd {
        flex-shrink: 0;
        width: 180px;
      }
    }
  }
}

.job-title-container {
  display: flex;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  background-image: url("./images/preclosure.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  margin-right: 20px;
}

.task_job {
  composes: icon;
  background-image: url("./images/taskgreen.svg");
  background-color: #ededed;
  padding: 10px;
  border-radius: 50px;
}

.meeting_job {
  composes: icon;
  background-image: url("./images/meeting-job.svg");
  background-color: #ededed;
  padding: 10px;
  border-radius: 50px;
}

.document_upload_job {
  composes: icon;
  background-image: url("./images/documentUpload.svg");
  background-color: #ededed;
  padding: 10px;
  border-radius: 50px;
}

.marker_job {
  composes: icon;
  background-image: url("./images/maker-job.svg");
  background-color: #ededed;
  padding: 10px;
  border-radius: 50px;
}

.view-jobs-filters-container {
  display: grid;
  align-items: center;
  column-gap: 20px;
  grid-template-columns: 180px 150px 150px 200px;

  .quick-search-container {
  }

  .status-dd-container {
  }
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.highlighted-information {
  font-size: 16px;
  color: #00b893;
  font-weight: 500;
}

.inventoryTitle {
  margin-bottom: 10px;
  color: #00b893;
}

.inventoryEmployeeList {
  width: 94%;
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  justify-content: space-around;
}

.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.bm-bdm-details {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  transform: translate(-10px, -50%);
  font-size: 11px;
  font-weight: 400;
  padding: 5px;

  // border: 1px solid grey;
  // border-radius: 10px;
  .bm-details {
    display: flex;
    gap: 5px;
    color: #464646;
    align-items: center;

    .bm-icon {
      background: url(./images/brand.svg) no-repeat;
      background-position: center;
      background-size: contain;
      width: 20px;
      height: 20px;
    }
  }

  .bdm-details {
    display: flex;
    margin-top: 5px;
    gap: 5px;
    color: #464646;
    align-items: center;

    .bdm-icon {
      background: url(./images/campaign.svg) no-repeat;
      color: #fff;
      background-position: center;
      background-size: contain;
      width: 20px;
      height: 20px;
    }
  }
}

.nav-section {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .nav-text {
    font-size: 14px;
    font-weight: 400;
    color: #363636;
    opacity: 0.6;
    padding: 10px;
    white-space: nowrap;
    cursor: pointer;
  }

  .nav-text-active {
    @extend .nav-text;
    opacity: 1;
    font-weight: 500;
    border-bottom: 4px solid #ff435e;
    font-size: 12px;
    transition-delay: 10ms;
  }
}

.reason {
  font-size: 12px;
  margin: 16px 0;
  border: 1px solid #000;
}

.remarks-text {
  font-size: 12px;
  padding-bottom: 5px;
  color: $red;
  font-weight: 400;
}

.flex-gap-10 {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.cloud-tag {
  padding: 4px 20px;
  background: #f5f5f5;
  text-align: center;
  margin: 5px 0px 5px 0px;
  width: fit-content;
  border-radius: 14px;
  font-size: 12px;
  float: right;
  position: relative;

  .cancel-icon {
    background: url("./images/cancel.svg") no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
    top: 50%;
    right: 3px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    transform: translate(0px, -50%);
  }
}

.tab-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;

  .text {
    padding: 10px 20px;
    align-self: stretch;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #363636;
  }

  .active-tab {
    border-bottom: 4px solid #ff435e;
    color: #363636;
    font-weight: 500;
  }
}

.icon-button {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background-position: center;
  background-size: 16px;
  background-color: rgba(0, 184, 147, 0.2);
  transition: 200ms;
  background-repeat: no-repeat;
  cursor: pointer;
}

.task-icon {
  @extend .icon-button;
  background-image: url("./images/task.svg");
}

.tooltiptext {
  visibility: hidden;
  display: inline;
  width: max-content;
  max-width: 50vw;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  top: -20px;
  left: 20px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-size: 12px;
}

.tip {
  position: relative;
  cursor: pointer;
}

.tip:hover .tooltiptext {
  visibility: visible;
}

.footer-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.user-icon {
  width: 24px;
  height: 24px;
  background: url("./images/user-black.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.role-icon {
  width: 24px;
  height: 24px;
  background: url("./images/role.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.i-icon {
  width: 24px;
  height: 24px;
  background: url("./images/info_icon_grey.svg") no-repeat;
}

.upsell-lead-selected-info-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid #f2f2f2;
  overflow-x: auto;
  width: 100%;
  flex-shrink: 0;
  font-size: 11px;

  .upsell-tag-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    overflow: auto;
    max-width: calc(100% - 110px);

    .upsell-lead-tag {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #00b893;
      border-radius: 4px;
      padding: 5px 8px;
      background-color: rgba(12, 184, 143, 0.1);
      border: solid 1px #00b893;
      flex-shrink: 0;
    }
  }

  .upsell-tag-container::-webkit-scrollbar {
    display: none;
  }
}
.campaign-accordian-container {
  width: 99%;
  height: 291px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-bottom: 10px;
  .document-container {
    padding-bottom: 25px;
    margin-left: 3%;
    font-size: 12px;
  }
  .accordian-base {
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px #00000014;
    transition: height 2s;
    .input-doc-container {
    }
    .input-holder {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 94%;
      margin-left: 3%;
    }
    .input-col {
      position: relative;
      margin-top: 10px;
      border-radius: 4px;
      width: 50%;
      padding-right: 10px;
      height: auto;
      margin-bottom: 10px;
    }
    .accordian-text {
      // height: 100%;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      // line-height: 28px;
      text-align: left;
      display: flex;
      align-items: center;
      position: relative;
      padding: 10px;
      .arrow-up {
        position: absolute;
        right: 30px;
        width: 20px;
        height: 20px;
        background: url("./images/accordian-arrow.svg") no-repeat;
        background-size: contain;
        background-position: center;
        transform: rotate(90deg);
        transition: 300ms;
      }
      .arrow-down {
        @extend .arrow-up;
        transform: rotate(270deg);
        transition: 300ms;
      }
    }
  }
}
.docs-list {
  display: flex;
  gap: 20px;
  padding-top: 20px;
}
.marginBtm0 {
  margin-bottom: 0px;
}
.input-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .info {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    background: url("./images/info_black.svg") no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }
  .info:hover::after {
    border-radius: 5px;
    font-size: 12px;
    content: attr(data-title);
    padding: 5px;
    width: 250px;
    border: 1px solid #0000004a;
    position: absolute;
    top: 18px;
    left: 14px;
    background: white;
    color: #666666;
    z-index: 1;
    white-space: break-spaces;
  }
  .cibil-info:hover::after {
    border-radius: 5px;
    font-size: 12px;
    content: attr(data-title);
    padding: 5px;
    width: 250px;
    border: 1px solid #0000004a;
    position: absolute;
    bottom: 25px;
    left: 0;
    background: white;
    color: #666666;
    z-index: 1;
    white-space: break-spaces;
  }
  .info-left:hover::after {
    border-radius: 5px;
    font-size: 12px;
    content: attr(data-title);
    padding: 5px;
    width: 250px;
    border: 1px solid #0000004a;
    position: absolute;
    top: 18px;
    right: 15px;
    background: white;
    color: #666666;
    z-index: 1;
    left: auto;
  }
  .info-ryt-36 {
    @extend .info;
    right: 28px;
  }
  .info-ryt-42 {
    @extend .info;
    right: 36px;
  }
  .info-ryt-20 {
    @extend .info;
    right: 12px;
  }
}
.input-100 {
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: auto;
}
.amount-dimension-new {
  @extend .amount-dimension;
  visibility: visible;
  display: flex;
  flex-flow: row-reverse;
  position: absolute;
  right: 0;
  bottom: -15px;
}
.map-search-container {
  display: flex;
  gap: 50px;
  width: 100%;
  padding: 20px;
  height: 400px;
  background: #fff;
  box-shadow: 0px 0px 7px 2px #dcd4d4;
  .map-container {
    width: 48%;
    height: 350px;
    border-radius: 4px;
  }
  .search-container {
    width: 48%;
    height: 350px;
    line-height: 24px;
    .header-input {
      font-size: 16px;
      // font-weight: 600;
      margin-top: 20px;
    }
    .search-button {
      margin-top: 20px;
    }
    .radio-buttons-container {
      margin-top: 10px;
      color: #363636;
      display: flex;
      align-items: center;
      gap: 40px;
      font-size: 14px;
    }
    .address-input {
      margin-top: 10px;
    }
  }
}
.no-data-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 30px;
  background-color: #a9a9a91a;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 2px #dcd4d4;
  padding-bottom: 10px;
  .no-data-image {
    width: 50px;
    height: 50px;
    background: url("./images/no-data.svg") no-repeat;
    background-size: contain;
    background-position: center;
  }
  .no-data-header {
    color: #363636e5;
    font-size: 18px;
    font-weight: 400;
  }
  .no-data-text {
    font-size: 14px;
    color: #363636e5;
    font-weight: 400;
  }
}
.table-footer-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: cornflowerblue;
}
.building-id-container {
  border-top: 1px solid #dad0d0;
  margin-top: 20px;
  .building-header {
    color: rgba(54, 54, 54, 0.9);
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .building-details {
    padding: 10px;
    background: #f9f9f9;
    // background: #ebebeb73;
    border-radius: 4px;
    margin-top: 10px;
    // white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }
  .building-label {
    color: rgba(54, 54, 54, 0.9);
    font-size: 16px;
    font-weight: 600;
  }
  .building-value {
    color: rgba(54, 54, 54, 0.9);
    font-size: 16px;
    font-weight: 400;
  }
  .building-button {
    font-size: 16px;
    font-weight: 500;
    border-color: rgb(12, 184, 143);
    color: rgb(12, 184, 143);
    border: 1px solid;
    width: 200px;
    padding: 5px;
    border-radius: 5px;
    background: #0cb88f24;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }
  .building-id-dropdown {
    margin-top: 20px;
  }
  .building-message {
    margin-top: 10px;
  }
  .building-message-green {
    font-size: 16px;
    color: rgb(12, 184, 143);
  }
  .building-message-red {
    font-size: 16px;
    color: #ff435e;
  }
  .mid-line {
    border: 1px solid black;
  }
}
.or-separator {
  margin-top: 30px;
  color: $grey;
  text-align: center;
}
.or-text {
  color: #000;
}
.auto-assign {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: #00b893;
  border-radius: 4px;
  letter-spacing: 0.52px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  margin-top: 30px;
}
.qualification-status-first {
  color: #ff435e;
  font-weight: 700;
  text-align: center;
  word-break: break-all;
}
.qualification-status-arrow {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  position: relative;
}
.qualification-status-second {
  color: #0c9675;
  font-weight: 700;
  text-align: center;
  word-break: break-all;
}
.close {
  width: 22px;
  height: 22px;
  background: url("./images/cancel.svg") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translate(0px, -50%);
  cursor: pointer;
}
.note-qualification-text {
  color: #ff435e;
  font-size: 14px;
  font-weight: 500;
}

.flex-class {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  width: 800px;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  height: 90vh;
  overflow: auto;
  background-color: transparent;
}

.modal-content {
  // background-color: #fff;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    flex-shrink: 0;
    width: 5%;
    color: #36363670;
    font-size: 12px;
  }
}
.mt-10 {
  margin-top: 10px;
}
.tag-holder {
  padding: 40px 0px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.note-text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #fd3753;
  // position: relative;
  // padding-left: 20px;
  // padding-right: 5px;
}
.notes-comment-section {
  width: 100%;
  .max-height-section {
    border: 1px solid #80808070;
    border-radius: 5px;
    padding: 20px 10px;
    overflow: hidden;
    max-height: 200px;
    overflow-y: scroll;
  }
}
.meet-image {
  height: 35px;
  width: 35px;
}
.virtual-meet-main {
  display: flex;
  gap: 15px;
}
.virtual-meet-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.share-quote {
  width: 100%;
  height: 16px;
  background: url("./images/share.svg") no-repeat;
  background-position: left;
  background-size: contain;
  font-size: 16px;
  color: green;
  line-height: normal;
  padding-left: 20px;
  cursor: pointer;
}
.input-search {
  width: 100%;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid $light-border;
}
.quantity-cta {
  background: cyan;
  padding: 5px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.warningContainer {
  padding: 30px;
}

.warningHeading {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 34px;
}
.warningQuote {
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
  }
}
.quoteTitle {
  font-size: 15px;
}
.quoteBox {
  display: flex;
  background-color: #f2f2f2;
  border-left: 3px solid #2e5fc2;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 16px;
  width: 200px;
}

.view-quote {
  width: 25px;
  height: 25px;
  background: url("./images/attachment.svg") no-repeat;
  background-position: center;
  background-size: contain;
  top: 66%;
  right: 10px;
  cursor: pointer;
}
.quote-name {
  padding: 15px 40px 0px;
  font-size: 14px;
  font-weight: 500;
}

.input-right-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .info {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    background: url("./images/currency-rupee.svg") no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.input-right-percentage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .info {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    background: url("./images/percentage.svg") no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.primary-lead-tag {
  padding: 5px 10px;
  border: 1px solid #ececec;
  border-radius: 15px;
  background: #02b893;
  color: #fff;
  margin-left: 5px;
}
.ticket-insight {
  width: 180px;
  padding: 2px 5px;
  background-color: #f5f6f7;
  border-radius: 4px;
  position: relative;
  .label {
    color: #666666;
    font-size: 13px;
    max-width: calc(100% - 10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .value {
    color: #333;
    font-size: 14px;
    //   font-weight: 500;
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 10px;
    height: 10px;
    background-image: url("./SocialCRM/images/arrow-right.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
  }
}
.initial-container {
  width: 100%;
  height: 60px;
  border: 1px solid #00000033;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  .edit-icon {
    width: 24px;
    height: 24px;
    background: url("./images/editIcon-green.svg") no-repeat;
    background-size: cover;
    background-position: center;
    margin: 8px;
  }
  .created-on {
    font-size: 14px;
    font-weight: 500;
    color: lightslategrey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }
}
.expanded-container {
  width: 100%;
  height: auto;
  border: 1px solid #00000033;
  background: #ffffff;
  border-radius: 7px;
  padding: 10px;
  margin-top: 10px;
  .expanded-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin-left: 20px;
  }
  .text-area-section {
    width: 96%;
    margin-left: 2%;
  }
  .icons-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .left-section {
    display: flex;
    gap: 10px;
    align-items: baseline;
    margin-left: 20px;
    width: 70%;
  }
  .remove-icon {
    cursor: pointer;
    color: #ff435e;
  }
  .remove-icon-disable {
    opacity: 0.4;
    color: #ff435e;
  }
  .attachment-icon {
    width: 24px;
    height: 24px;
    background: url("./images/attachment-green.svg") no-repeat;
    background-position: center;
    background-size: 22px;
    cursor: pointer;
  }
  .right-section {
    margin-right: 20px;
    padding-top: 5px;
  }
  .save-icon {
    width: 20%;
    cursor: pointer;
    color: #00b893;
  }
  .save-icon-disable {
    width: 20%;
    opacity: 0.4;
  }
  .add-file-disabled {
    opacity: 0.4;
    width: 100px;
  }
  .add-file {
    color: #00b893;
    cursor: pointer;
    width: 100px;
  }
  .doc-list-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .doc-container {
    font-size: 12px;
    margin-right: 5px;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-top: 3px;
    max-width: 120px;
    padding: 5px 20px;
    background-color: #f5f6f7;
  }

  .cross-icon {
    cursor: pointer;
    top: 4px;
    right: 2px;
    width: 15px;
    height: 15px;
    opacity: 0.7;
    background: url("./images/cancelBlack.svg") no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
  }
}
.click-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: darkgray;
}
.popup-card {
  // position: fixed;
  // bottom: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 26px #00000029;
  border-radius: 6px;
  margin-left: 2%;
  margin: 5px;
  height: 55px;
  .icon-with-text {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .error-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: url("./images/cancel-red.svg") no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.preview-meeting-container {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100%;
  justify-content: center;
  background-color: #00000099;
  justify-content: center;
  display: flex;
  align-items: center;
  .preview-button {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
    background-color: white;
  }
}
.doc-name {
  color: #00b893;
  font-size: 14px;
  margin-left: 10px;
}
.kyc-doc-container {
  // border: 1px solid #dedede;
  padding: 10px;
  margin: 10px;
  .doc-container {
    border: 1px solid #dedede;
    padding: 10px;
    margin-top: 10px;
    border-radius: 14px;
  }
  .kyc-header {
    font-size: 16px;
    font-weight: 500;
  }
  .kyc-doc-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    // justify-content: center;
    margin-top: 10px;
  }
  .kyc-docs {
    width: 100px;
    // height: 100px;
    border: 2px solid #dedede;
  }
}
.verification-button-container {
  display: flex;
  width: 100%;
  align-items: center;
  .verify-input {
    display: flex;
  }
  .current-status {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    background: #00b893;
    color: #fff;
    padding: 3px 10px;
    border-radius: 10px;
    border: 2px dotted lightgray;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 2px;
    height: 30px;
  }
  .click-to-verify-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .verified-icon {
    height: 20px;
    width: 20px;
    background-image: url("./images/success-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.show-confirmation {
  width: 30%;
  margin: 16% auto;
  box-shadow: 0px 0px 7px 2px #dcd4d4;
}
.validation-message-container {
  margin-left: 3%;
  line-height: 30px;

  .validation-message {
    display: flex;
  }
  .validation-label {
    width: 25%;
    color: #666666;
    font-weight: 600;
  }
  .validation-value {
    font-size: 16px;
    font-weight: 600;
  }
  .validation-question {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    line-height: 40px;
    letter-spacing: 0.1rem;
  }
}
.notes-count {
  position: absolute;
  top: 80px;
  left: 40px;
  color: $red;
  font-size: 14px;
  font-weight: 500;
}
.otp-inv-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  .header-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .otp-section {
    display: flex;
    flex-direction: column;
    // gap: 20px;
  }

  .select-otp {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .otp-info {
    color: green;
    font-size: 14px;
    margin-top: 2px;
  }

  /* Separate section for verifying OTP */
  .otp-verification-section {
    margin-top: 25px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f5f5f5;
  }

  .section-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .otp-verify {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
  }

  button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
.error-note {
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ff435e;
  background: #f5f5f5;
  color: #ff435e;
  font-size: 16px;
  font-weight: 600;
}
.society-container {
  padding: 20px;

  .society-details {
    width: 100%;
    background: #f5f5f5;
    position: relative;
    padding: 15px 20px;
  }
  .society-row {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .society-header {
    font-size: 14px;
    font-weight: 500;
    color: $black;
    flex-shrink: 0;
  }
  .col25 {
    width: 22%;
    flex-shrink: 0;
  }
  .label {
    width: 100%;
    font-size: 11px;
    color: $black3;
  }
  .info {
    width: 100%;
    max-width: 200px;
    font-size: 12px;
    font-weight: 500;
    color: $black;
    line-height: 16px;
    margin-top: 5px;
  }
  .capitalizeCity {
    text-transform: capitalize;
  }
}
.show-edit-society-modal {
  width: 70%;
  margin: 5% auto;
}
.footer-wrapper {
  width: 100%;
  position: sticky;
  bottom: 0;
}
.document-container-grid-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  gap: 10px;
  margin-top: 10px;
  .document-item {
    display: flex;
    flex-direction: column;
  }

  .document-upload-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }

  .uploaded-file {
    font-size: 14px;
    color: #333;
  }

  .icon-group {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .document-type {
    font-size: 14px;
    // color: #fff;
    // margin-top: -10px;
    // background: #b5b3b3;
    padding: 0px 10px;
    // border-radius: 5px;
    // margin-left: 8px;
  }
  .document-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 10px;

    .uploaded-file {
      flex-grow: 1;
      margin: 0 10px;
      color: #666;
      max-width: 50%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      padding: 5px 20px;
      background-color: #f5f6f7;
    }
    .delete-icon-red {
      // color: #e74c3c;
      width: 20px;
      height: 20px;
      background-image: url("./images/cancel_red.svg");
      background-color: $white;
      background-size: contain;
      background-position: center;
      cursor: pointer;
    }
    .icon-group {
      display: flex;
      gap: 20px;

      .icon-container {
        font-size: 1.2rem;
        cursor: pointer;
        transition: transform 0.2s;
      }
    }
  }
}
.delete-button {
  width: 20px;
  height: 20px;
  background-image: url("./images/delete-red.svg");
  background-color: $white;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

.add-text-button {
  font-size: 12px;
  font-weight: 500;
  color: #0cb88f;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 90%;
}
.not-found {
  position: absolute;
  left: 40%;
  bottom: 55%;
  color: #f83a69;
}
.invoice-popup-card {
  position: fixed;
  bottom: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 26px #00000029;
  border-radius: 6px;
  margin-left: 3%;
  width: fit-content;
  height: 55px;
  .icon-button {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    background-position: center;
    background-size: 16px;
    background-color: #ebebeb;
    transition: 200ms;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .error-icon {
    @extend .icon-button;
    background: transparent;
    background: url("./images/cancel_red.svg") no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    width: 25px;
    background-size: contain;
  }
  .icon-with-text {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
