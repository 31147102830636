.container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid black;
  background-color: white;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
  border: 1px solid #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  transform: translate(-50%, -50%);
}
.label {
  font-size: 14px;
  font-weight: 500;;
}
.city {
   font-weight: normal;;
  text-transform: capitalize;
  margin-left: 2px;
  letter-spacing: 0.3px;
  font-size: 10px;
}
.email {
   font-weight: normal;;
  font-size: 10px;
  color: #999;
}

.container input:disabled ~ .checkmark {
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: not-allowed;
}

.container:hover input:disabled ~ .checkmark {
  cursor: not-allowed;
}