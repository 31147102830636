* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebebeb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dedede;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dedede;
}
