@import "./variables.scss";
.base {
  position: relative;
  width: auto;
  display: flex;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid $white;
  padding: 0px 5px;
}

.initials {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid $pink;
  background: $light-pink;
  color: $dark-pink;
  text-align: center;
  font-size: 18px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.name {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: $white;
}
.action-btn {
  width: 18px;
  height: 18px;
  background: url("./images/forward_white.svg") no-repeat;
  transform: rotate(90deg);
  margin-bottom: 5px;
  cursor: pointer;
}
.action-btn-color {
  width: 18px;
  height: 18px;
  background: url("./images/forward.svg") no-repeat;
  transform: rotate(90deg);
  margin-bottom: 5px;
  cursor: pointer;
}
.option {
  display: flex;
  align-items: center;
}
.status {
  height: 13px;
  width: 13px;
  background-color: orange;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 30px;
}
.icon {
  width: 14px;
  height: 14px;
}
