@import "../variables.scss";
.base {
  width: 350px;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  transition: 300ms;
  transform: translateX(100%);
  z-index: 200;
}
.open {
  composes: base;
  transform: translateX(0);
}
.panel-open {
  width: 60px;
  height: 60px;
  border-radius: 30px 0 0 30px;
  background: $card-border url("../images/phoneIcon.svg") no-repeat;
  background-position: center;
  background-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60px;
  margin: auto;
  cursor: pointer;
}
.sales-pitch {
  width: 60px;
  height: 60px;
  border-radius: 30px 0 0 30px;
  background: $card-border url("../images/C360.png") no-repeat;
  background-position: center;
  background-size: 30px;
  position: absolute;
  top: calc(50% + 240px);
  bottom: 0;
  left: -60px;
  cursor: pointer;
}
.iframe {
  width: 100%;
  height: 100%;
}
.chat-leads {
  width: 60px;
  height: 60px;
  border-radius: 30px 0 0 30px;
  background: $card-border url("../images/allChats.svg") no-repeat;
  background-position: center;
  background-size: 30px;
  position: absolute;
  top: calc(50% + -230px);
  bottom: 0;
  left: -60px;
  cursor: pointer;
}
.unreadCount {
  height: 25px;
  width: 25px;
  background: #5473d5;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  position: relative;
  bottom: 5px;
  right: 7px;
  padding: 2px;
  font-size: 16px;
  font-weight: 500;
}
.chat-icon-loader {
  width: 30px;
  height: 30px;
  margin: auto;
  border: 6px solid rgba(70, 70, 70, 0.8);
  border-radius: 30px 0 0 30px;
  border-top: 6px solid $card-border;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.chat-leads-loader {
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: 30px;
  position: absolute;
  top: calc(50% + -216.2px);
  bottom: 0;
  left: -60px;
  cursor: pointer;
  opacity: 0.7;
}
.notification-count{
  height: fit-content;
  width: fit-content;
  background: #5473d5;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  position: relative;
  bottom: 5px;
  right: 7px;
  padding: 3px;
  font-size:13px;
  font-weight: 500;
}