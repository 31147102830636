@import "../variables.scss";
.base {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 10px;
  position: relative;
  gap: 10px;
  .initials {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid $pink;
    background: $light-pink;
    color: $dark-pink;
    text-align: center;
    margin-right: 12px;
    font-size: 50px;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 10px;
  }
  .first-box {
    display: flex;
  }
  .name-email {
    margin-left: 20px;
    .phone-icon {
      background-image: url("../images/calling.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }
    .email-icon {
      background-image: url("../images/email-grey.svg");
      background-repeat: no-repeat;
    }
    .role-icon {
      background-image: url("../images/role.svg");
      background-repeat: no-repeat;
    }
    .manager-icon {
      background-image: url("../images/reporting-manager.svg");
      background-repeat: no-repeat;
    }
  }
  .icon-box {
    border-right: 1px solid #ececec;
  }
  .toggle-buttons {
    // border: 1px solid #adadad;
    width: 130px;
    height: 30px;
    display: flex;
    cursor: pointer;
    margin-right: 25px;
    align-items: center;
    margin-top: 10px;
    border-radius: 20px;
    .button {
      width: 80px;
      font-size: 12px;
      border-radius: 25px;
      padding: 8px;
    }
  }
  .cross-icon {
    width: 26px;
    height: 26px;
    background: url("../images/cancel.svg") no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    transform: translate(-50%, -50%);
  }
  .label {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
  }
  .key {
    font-weight: 400;
    font-size: 14px;
    margin-left: 25px;
  }
  .line {
    width: 100%;
    border: 1px solid #ececec;
  }
  .edit-button {
    padding-right: 18px;
    color: $green;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
    margin-left: 5px;
  }
  .edit-button:before {
    content: "";
    vertical-align: middle;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url("../images/edit.svg");
  }
  .form-container {
    width: 100%;
    padding: 20px;
  }
  .row {
    width: 100%;
    margin-bottom: 24px;
  }
  .button-container {
    width: 100%;
    margin-bottom: 10px;
  }
}
.checkbox-header {
  margin-top: 10px;
  border-bottom: 1px solid #f1ecec;
  font-size: 15px;
}
.checkbox-container {
  // padding: 15px 40px 0px 40px;
  // position: relative;
  // margin-top: 10px;
}
.two-checkboxes {
  display: flex;
  justify-content: space-between;
}
